<template>
  <div class="case">
    <Banner></Banner>
    <div class="case-list">
      <div class="case-list-content">
        <div class="case-list-search">
          <input type="text" v-model="keyword" @keyup.enter="searchCase" placeholder="请输入关键词进行搜索">
          <div class="case-list-search-btn" @click="searchCase">搜索</div>
        </div>
        <div class="case-screen">
          <div class="case-screen-row">
            <div class="case-screen-label">行业分类</div>
            <ul>
              <li
                :class="0 === industryId ? 'case-screen-active' : ''"
                @click="industryChange(0)"
              >
                全部
              </li>
              <li
                :class="item.id === industryId ? 'case-screen-active' : ''"
                v-for="item in classify.industryList"
                :key="item.id"
                @click="industryChange(item.id)"
              >
                {{item.name}}
              </li>
            </ul>
          </div>
          <div class="case-screen-row">
            <div class="case-screen-label">类型</div>
            <ul>
              <li
                :class="0 === typesId ? 'case-screen-active' : ''"
                @click="typesChange(0)"
              >
                全部
              </li>
              <li
                :class="item.id === typesId ? 'case-screen-active' : ''"
                v-for="item in classify.typesList"
                :key="item.id"
                @click="typesChange(item.id)"
              >
                {{item.name}}
              </li>
            </ul>
          </div>
          <div class="case-screen-row">
            <div class="case-screen-label">功能</div>
            <ul>
              <li
                :class="0 === functionsId ? 'case-screen-active' : ''"
                @click="functionsChange(0)"
              >
                全部
              </li>
              <li
                v-for="item in classify.functionsList"
                :key="item.id"
                @click="functionsChange(item.id)"
                :class="item.id === functionsId ? 'case-screen-active' : ''"
              >
                {{item.name}}
              </li>
            </ul>
          </div>
          <div class="case-screen-row">
            <div class="case-screen-label">节日营销</div>
            <ul>
              <li
                :class="0 === festivalsId ? 'case-screen-active' : ''"
                @click="festivalsChange(0)"
              >
                全部
              </li>
              <li
                v-for="item in classify.festivalsList"
                :key="item.id"
                @click="festivalsChange(item.id)"
                :class="item.id === festivalsId ? 'case-screen-active' : ''"
              >
                {{item.name}}
              </li>
            </ul>
          </div>
        </div>
        <div class="mobile-case-screen">
          <div class="mobile-case-screen-nav">
            <span :class="mobileNav == 'industryList' ? 'mobile-case-screen-nav-active' : ''" @click="mobileNav = 'industryList'">行业分类</span>
            <span :class="mobileNav == 'typesList' ? 'mobile-case-screen-nav-active' : ''" @click="mobileNav = 'typesList'">类型</span>
            <span :class="mobileNav == 'functionsList' ? 'mobile-case-screen-nav-active' : ''" @click="mobileNav = 'functionsList'">功能</span>
            <span :class="mobileNav == 'festivalsList' ? 'mobile-case-screen-nav-active' : ''" @click="mobileNav = 'festivalsList'">节日营销</span>
          </div>
          <div class="mobile-nav-list" v-show="mobileNav == 'industryList'">
            <div
              :class="0 === industryId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
              @click="mobileChange(0)"
            >
              全部
            </div>
            <div
              v-for="item in classify.industryList"
              :key="item.id"
              @click="mobileChange(item.id)"
              :class="item.id === industryId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
            >
              {{item.name}}
            </div>
          </div>
          <div class="mobile-nav-list" v-show="mobileNav == 'typesList'">
            <div
              :class="0 === typesId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
              @click="mobileChange(0)"
            >
              全部
            </div>
            <div
              v-for="item in classify.typesList"
              :key="item.id"
              @click="mobileChange(item.id)"
              :class="item.id === typesId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
            >
              {{item.name}}
            </div>
          </div>
          <div class="mobile-nav-list" v-show="mobileNav == 'functionsList'">
            <div
              :class="0 === functionsId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
              @click="mobileChange(0)"
            >
              全部
            </div>
            <div
              v-for="item in classify.functionsList"
              :key="item.id"
              @click="mobileChange(item.id)"
              :class="item.id === functionsId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
            >
              {{item.name}}
            </div>
          </div>
          <div class="mobile-nav-list" v-show="mobileNav == 'festivalsList'">
            <div
              :class="0 === festivalsId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
              @click="mobileChange(0)"
            >
              全部
            </div>
            <div
              v-for="item in classify.festivalsList"
              :key="item.id"
              @click="mobileChange(item.id)"
              :class="item.id === festivalsId ? 'mobile-nav-item case-screen-active' : 'mobile-nav-item'"
            >
              {{item.name}}
            </div>
          </div>
        </div>
        <ul class="case-list-box">
          <li
            class="case-item"
            @click="toCaseUrl(item)"
            v-for="item in caseList"
            :key="item.id"
          >
            <div class="case-item-phone">
              <div class="case-item-img" :style="`background-image: url(${item.cover})`">
                <div class="case-item-img-hover">
                  <div class="case-item-qrcode">
                    <img :src="item.qr_code">
                    <span>钉钉/微信扫码观看</span>
                    <p>{{item.describe}}</p>
                  </div>
                  <div class="case-item-buy" @click="onPreview(item.qr_code_url, )">点击预览</div>
                  <div class="case-item-consult" @click="onCustom(item.id)">咨询定制</div>
                  <div class="case-item-buy" @click="popShow = 2">购买模板</div>
                </div>
              </div>
            </div>
            <div class="case-item-desc">
              <div class="case-item-title">{{item.title}}</div>
              <ul class="case-item-label">
                <li v-for="(i, index) in item.labelArr" :key="index">
                  {{i}}
                </li>
              </ul>
              <!-- <div class="case-item-price">
                <span class="case-item-price-label">定制预算:</span>
                <span>{{item.case_budget_a}}</span>
              </div>
              <div class="case-item-price">
                <span class="case-item-price-label">模板预算:</span>
                <span>{{item.budget_a}} - {{item.budget_b}}</span>
              </div> -->
              <div class="mobile-case-item-btn">
                <div class="case-item-consult" @click="onCustom(item.id)">咨询定制</div>
                <div class="case-item-buy" @click="popShow = 2">购买模板</div>
              </div>
            </div>
          </li>
        </ul>
        <div class="case-list-load" ref="listLoad">
          {{complete ? '没找到匹配的案例模型, 联系我们为您解答~' : '加载中...'}}
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <div class="pop" v-show="popShow !== 0" @click.self="popClose">
      <div class="pop-custom" v-show="popShow === 1">
        <img class="close" @click="popClose" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402270e092b5021dc0f62547730.png">
        <div class="custom-title">咨询定制</div>
        <div class="custom-form">
          <div class="custom-form-item">
            <div class="custom-form-label">
              联系人姓名：
            </div>
            <div class="custom-form-content">
              <input class="custom-form-input" v-model="contactUser" type="text" placeholder="请输入姓名">
            </div>
          </div>
          <div class="custom-form-item">
            <div class="custom-form-label">
              联系人电话：
            </div>
            <div class="custom-form-content">
              <input class="custom-form-input" v-model="contactMobile" type="text" onkeyup="value=value.replace(/[^1\d{10}]/)" maxlength="11" placeholder="请输入联系方式">
            </div>
          </div>
          <div class="custom-form-item">
            <div class="custom-form-label">
              我想做：
            </div>
            <div class="custom-form-content">
              <div :class="customType == 'H5' ? 'custom-form-type custom-form-type-active' : 'custom-form-type'" @click="customType = 'H5'">
                <div class="custom-form-type-icon"></div>
                <span>H5</span>
              </div>
              <div :class="customType == '小程序' ? 'custom-form-type custom-form-type-active' : 'custom-form-type'" @click="customType = '小程序'">
                <div class="custom-form-type-icon"></div>
                <span>小程序</span>
              </div>
              <div :class="customType == '网站' ? 'custom-form-type custom-form-type-active' : 'custom-form-type'" @click="customType = '网站'">
                <div class="custom-form-type-icon"></div>
                <span>网站</span>
              </div>
              <div :class="customType == 'SVG动画' ? 'custom-form-type custom-form-type-active' : 'custom-form-type'" @click="customType = 'SVG动画'">
                <div class="custom-form-type-icon"></div>
                <span>SVG动画</span>
              </div>
              <div :class="customType == 'AR/VR' ? 'custom-form-type custom-form-type-active' : 'custom-form-type'" @click="customType = 'AR/VR'">
                <div class="custom-form-type-icon"></div>
                <span>AR/VR</span>
              </div>
            </div>
          </div>
          <div class="custom-form-item">
            <div class="custom-form-label">
              项目背景：
            </div>
            <div class="custom-form-content">
              <input class="custom-form-input" v-model="background" type="text" placeholder="请描述你的项目背景">
            </div>
          </div>
          <div class="custom-form-item">
            <div class="custom-form-label">
              大致预算：
            </div>
            <div class="custom-form-content">
              <div :class="customBudget == '1万以下' ? 'custom-form-budget custom-form-budget-active' : 'custom-form-budget'" @click="customBudget = '1万以下'">
                1万以下
              </div>
              <div :class="customBudget == '2~3万' ? 'custom-form-budget custom-form-budget-active' : 'custom-form-budget'" @click="customBudget = '2~3万'">
                2~3万
              </div>
              <div :class="customBudget == '3~4万' ? 'custom-form-budget custom-form-budget-active' : 'custom-form-budget'" @click="customBudget = '3~4万'">
                3~4万
              </div>
              <div :class="customBudget == '4~5万' ? 'custom-form-budget custom-form-budget-active' : 'custom-form-budget'" @click="customBudget = '4~5万'">
                4~5万
              </div>
              <div :class="!['1万以下', '2~3万', '3~4万', '4~5万'].includes(customBudget) ? 'custom-form-budget custom-form-budget-active' : 'custom-form-budget'">
                <input v-model="budgetValue" @focus="customBudget = budgetValue" @input="customBudget = budgetValue" class="custom-form-budget-input" type="text" placeholder="其他金额">
              </div>
            </div>
          </div>
        </div>
        <div class="custom-ercode">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403186e0c29d564648695575079.png">
          <span>微信扫码联系我们更快哟~</span>
        </div>
        <div class="custom-submit" @click="customSubmit">提交</div>
      </div>

      <div class="pop-buy" v-show="popShow === 2">
        <img class="close" @click="popClose" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402270e092b5021dc0f62547730.png">
        <div class="buy-title">购买模板</div>
        <div class="buy-ercode">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403186e0c29d564648695575079.png">
          <span>请微信扫码联系我们购买哟~</span>
        </div>
        <div class="buy-submit" @click="popShow = 0">确认</div>
      </div>

      <div class="pop-preview" v-show="popShow === 3">
        <img src="https://image.xiaoxxx.cn/test/urlGather/202409098721bc943729e9b5466592.png">
        <div class="iframe-box">
          <iframe :src="previewUrl" sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-presentation allow-same-origin allow-scripts" allow="geolocation; microphone; camera;midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
export default {
  components: {
    Banner
  },
  data() {
    return {
      keyword: '',
      classify: {
        industryList: [],
        festivalsList: [],
        functionsList: [],
        typesList: []
      },
      industryId: 0,
      festivalsId: 0,
      functionsId: 0,
      typesId: 0,
      page: 1,
      caseList: [],
      listLoading: false,
      complete: false,
      mobileNav: 'industryList',

      popShow: 0,
      caseId: 0,
      contactUser: '',
      contactMobile: '',
      background: '',
      customType: 'H5',
      customBudget: '1万以下',
      budgetValue: '',

      previewUrl: ''
    }
  },
  mounted() {
    // 标签列表
    this.api.labelList().then(res => {
      this.classify.industryList = res.data.industryList
      this.classify.festivalsList = res.data.festivalsList
      this.classify.functionsList = res.data.functionsList
      this.classify.typesList = res.data.typesList
    })

    this.getCaseList()

    const intersectionObserver = new IntersectionObserver((entries) => {
      // 如果 intersectionRatio 为 0，则目标在视野外，
      // 我们不需要做任何事情。
      if (entries[0].intersectionRatio <= 0) return;

      // loadItems(10);
      if (!this.complete) {
        console.log("Loaded new items");
        this.listLoading = true
        this.page += 1
        this.getCaseList()
      }
    });
    // 开始监听
    intersectionObserver.observe(this.$refs.listLoad);
  },
  methods: {
    toCaseUrl(item) {
      this.api.caseClick({caseId: item.id})
      if (window.innerWidth <= 750) {
        window.open(item.qr_code_url, '_blank')
      }
    },
    getCaseList() {
      this.api.caseList({
        page: this.page,
        pageSize: 10,
        category: 1,
        keyword: this.keyword,
        industryId: this.industryId,
        typeId: this.typesId,
        functionId: this.functionsId,
        festivalId: this.festivalsId
      }).then(res => {
        if (res.data.lists.length <= 0) {
          this.complete = true
        } else {
          res.data.lists.forEach(element => {
            element.labelArr = element.label ? element.label.split(",") : []
          });
          this.caseList.push(...res.data.lists)
        }
        this.listLoading = false
      })
    },
    searchCase() {
      this.typesId = 0
      this.functionsId = 0
      this.festivalsId = 0
      this.caseList = []
      this.page = 1
      this.complete = false
      this.getCaseList()
    },
    mobileChange(id) {
      switch (this.mobileNav) {
        case 'industryList':
          this.industryChange(id)
          break;
        case 'typesList':
          this.typesChange(id)
          break;
        case 'functionsList':
          this.functionsChange(id)
          break;
        case 'festivalsList':
          this.festivalsChange(id)
          break;
      }
    },
    industryChange(id) {
      this.keyword = ''
      this.industryId = id
      this.caseList = []
      this.page = 1
      this.complete = false
      this.getCaseList()
    },
    typesChange(id) {
      this.keyword = ''
      this.typesId = id
      this.caseList = []
      this.page = 1
      this.complete = false
      this.getCaseList()
    },
    functionsChange(id) {
      this.keyword = ''
      this.functionsId = id
      this.caseList = []
      this.page = 1
      this.complete = false
      this.getCaseList()
    },
    festivalsChange(id) {
      this.keyword = ''
      this.festivalsId = id
      this.caseList = []
      this.page = 1
      this.complete = false
      this.getCaseList()
    },
    onCustom(id) {
      this.caseId = id
      this.popShow = 1
    },
    onPreview(url) {
      console.log('url', url)
      this.previewUrl = url
      this.popShow = 3
    },
    popClose() {
      this.popShow = 0
      this.previewUrl = ''
    },
    customSubmit() {
      this.api.submit({
        caseId: this.caseId,
        contactUser: this.contactUser,
        contactMobile: this.contactMobile,
        intention: this.customType,
        background: this.background,
        budget: this.customBudget
      }).then(() => {
        this.popShow = 0

        this.caseId = 0
        this.contactUser = '',
        this.contactMobile = '',
        this.background = '',
        this.customType = 'H5',
        this.customBudget = '1万以下',
        this.budgetValue = ''
      })
    }
  }
}
</script>

<style scoped>
.case {
  width: 100%;
  min-width: var(--maxWidth);
}

.case-list {
  width: 100%;
  padding: 40px 0;
}
.case-list-content {
  width: var(--maxWidth);
  margin: 0 auto;
}
.case-list-search {
  width: 680px;
  height: 50px;
  background: #f8faff url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022246452e2e19e07814251263.png') 30px center / 26px no-repeat;
  border: 1px solid #f1f1f1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 7px 0 75px;
  margin: 0 auto;
}
.case-list-search>input {
  width: 500px;
  height: 50px;
  font-size: 16px;
  border: none;
  background: transparent;
  outline: none;
  margin: 0;
  padding: 0;
}
.case-list-search-btn {
  width: 120px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background-image: linear-gradient(to right, #ff5c77, #e7092f);
  border-radius: 40px;
  cursor: pointer;
}
.case-screen {
  margin-top: 50px;
}
.mobile-case-screen {
  display: none;
}
.case-screen-row {
  margin: 0 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.case-screen-label {
  width: 80px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}
.case-screen-row>ul {
  width: 880px;
  font-size: 14px;
  color: #000;
  display: flex;
  flex-wrap: wrap;
}
.case-screen-row li {
  margin: 2px 10px 5px;
  padding: 5px 12px;
  cursor: pointer;
}
.case-screen-active {
  color: #fff;
  background: #e60b31;
  border-radius: 8px;
}
.case-list-box {
  display: flex;
  flex-wrap: wrap;
}
.case-item {
  width: 240px;
  margin: 0 0px 30px 0;
}
.case-item:nth-of-type(4n) {
  margin-right: 0;
}
.case-item-phone {
  width: 100%;
  height: 460px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240221e410bac21c06af11521858.png') center / 100% 100% no-repeat;
  box-sizing: border-box;
  padding: 20px;
}
.case-item-img {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  mask: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022167dd95c144d72868211953.png') center / 100% 100% no-repeat;
}
.case-item-img-hover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s;
}
.case-item-qrcode {
  width: 180px;
  background: linear-gradient(#f7afbb 0%, #fff 20%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  margin: 10px 0 20px;
}
.case-item-qrcode>img {
  width: 100px;
  margin-bottom: 10px;
}
.case-item-qrcode>span {
  font-size: 14px;
  color: #0a0a0a;
}
.case-item-qrcode>p {
  width: 100%;
  font-size: 12px;
  border-top: 1px solid #fce6ea;
  margin-top: 10px;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-item-consult {
  width: 120px;
  height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  background: #e60b31;
  border-radius: 30px;
  margin: 10px 0;
  cursor: pointer;
}
.case-item-buy {
  width: 120px;
  height: 30px;
  font-size: 14px;
  color: #e60b31;
  text-align: center;
  line-height: 30px;
  background: #fff;
  border-radius: 30px;
  cursor: pointer;
}
.case-item-phone:hover .case-item-img-hover{
  opacity: 1;
}
.case-item-desc {
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
}
.case-item-title {
  font-size: 16px;
  color: #000;
  margin: 10px 0;
}
.case-item-label {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.case-item-label li {
  font-size: 12px;
  color: #e6082e;
  background: #feebec;
  padding: 2px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.case-item-price {
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
}
.case-item-price-label {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 10px;  
}
.mobile-case-item-btn {
  display: none;
}
.case-list-load {
  width: 100%;
  height: 50px;
  font-size: 12px;
  color: #000;
  text-align: center;
  line-height: 50px;
}

.pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
}
.pop-custom {
  width: 690px;
  background: #fff url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240227e16d80e6fd5c0a3c299432.png') center top / 100% no-repeat;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 35px 50px 30px;
}
.custom-title {
  font-size: 26px;
  font-weight: 700;
  color: #020202;
  text-align: center;
  margin-bottom: 36px;
}
.custom-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-form-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.custom-form-label {
  font-size: 14px;
  color: #646a73;
  margin-right: 10px;
}
.custom-form-content {
  width: 490px;
  display: flex;
  justify-content: space-between;
}
.custom-form-input {
  width: 100%;
  height: 35px;
  font-size: 14px;
  color: #464646;
  background: #f8faff;
  border: none;
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  padding: 0 15px;
}
.custom-form-input::placeholder{
  color:rgba(0, 0, 0, 0.3);
}
.custom-form-type {
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  background: #f8faff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.custom-form-type-icon {
  width: 30px;
  height: 30px;
  background: #454545;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}
.custom-form-type:nth-of-type(1) .custom-form-type-icon {
   mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022739601259ca937f09708099.png');
}
.custom-form-type:nth-of-type(2) .custom-form-type-icon {
   mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402279fb91db2ff9d823b229869.png');
}
.custom-form-type:nth-of-type(3) .custom-form-type-icon {
   mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240227d964a82d8ecda7fa807283.png');
}
.custom-form-type:nth-of-type(4) .custom-form-type-icon {
   mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022703a6e6c9a3cffd69788341.png');
}
.custom-form-type:nth-of-type(5) .custom-form-type-icon {
   mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240227f283914c262bc61f235151.png');
}
.custom-form-type>span {
  font-size: 12px;
  color: #454545;
  margin-top: 10px;
}
.custom-form-type-active {
  background: #fff7f8;
  border: 2px solid #f3889a;
}
.custom-form-type-active .custom-form-type-icon {
  background: #e60b31;
}
.custom-form-type-active>span {
  color: #e60b31;
}
.custom-form-budget {
  width: 90px;
  height: 38px;
  font-size: 14px;
  color: #454545;
  text-align: center;
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 6px;
  background: #f8faff;
  cursor: pointer;
}
.custom-form-budget-active {
  color: #e60b31;
  line-height: 34px;
   background: #fff7f8;
  border: 2px solid #f3889a;
}
.custom-form-budget-input {
  width: 100%;
  height: 100%;
  text-align: center;
  background: none;
  border: none;
  border-radius: 6px;
  outline: none;
}
.custom-form-budget-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.custom-ercode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-ercode>img {
  width: 150px;
}
.custom-ercode>span {
  font-size: 14px;
  color: #464646;
}
.custom-submit {
  width: 590px;
  height: 48px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  background-image: linear-gradient(to right, #ff627d, #e6082e);
  border-radius: 6px;
  margin-top: 24px;
  cursor: pointer;
}
.pop-buy {
  width: 360px;
  background: #fff url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240227f33805b09e6cb23b717929.png') center top / 100% no-repeat;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buy-title {
  font-size: 26px;
  font-weight: 700;
  color: #020202;
  text-align: center;
  margin-bottom: 20px;
}
.buy-ercode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buy-ercode>img {
  width: 150px;
}
.buy-ercode>span {
  font-size: 14px;
  color: #464646;
}
.buy-submit {
  width: 260px;
  height: 48px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  background-image: linear-gradient(to right, #ff627d, #e6082e);
  border-radius: 6px;
  margin-top: 24px;
  cursor: pointer;
}
.pop-preview {
  width: 445px;
  height: 910px;
  box-sizing: border-box;
  padding: 20px 16px 20px 20px;
  position: relative;
  margin-top: 40px;
  transform: scale(0.7);
}
.pop-preview>img {
  width: 100%;
  height: 100%;
  /* width: calc(100% + 2px); */
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  pointer-events: none;
  /* opacity: 0.4; */
}
.iframe-box {
  width: 100%;
  height: 100%;
  background: #000;
  box-sizing: border-box;
  padding: 0 2px;
  border-radius: 30px;
  
}
.pop-preview iframe {
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
}
.pop-preview iframe::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.pop-preview iframe html::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
.pop-preview iframe body::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}
@media screen and (max-width: 750px) {
  .case {
    min-width: 100%;
  }
  .banner {
    height: 50vw;
  }
  .banner-flow-item {
    width: 120px;
    height: 236px;
    padding: 10px;
    margin-top: 10px;
  }
  .banner-text {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240520a1ccf5a72f5b642f303679.png');
    width: 85%;
  }
  .case-list {
    padding: 20px 0;
    background-image: linear-gradient(#faf4f4, #fafafb);
  }
  .case-list-content {
    width: 100%;
  }
  .case-list-search {
    width: 92vw;
    height: 45px;
    background: #fff url('https://image.xiaoxxx.cn/test/urlGather/20240521244c4073621e2df5835997.png') 20px center / 20px no-repeat;
    box-shadow: 0 8px 10px 0 rgba(110, 0, 19, 0.1);
    padding: 0 7px 0 50px;
  }
  .case-list-search>input {
    font-size: 12px;
  }
  .case-list-search-btn {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 30px;
  }

  .case-screen {
    display: none;
  }
  .mobile-case-screen {
    width: 92vw;
    margin: 20px auto;
    display: block;
  }
  .mobile-case-screen-nav {
    display: flex;
  }
  .mobile-case-screen-nav span {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-right: 20px;
    position: relative;
  }
  .mobile-case-screen-nav-active::after {
    content: '';
    width: 15px;
    height: 3px;
    border-radius: 3px;
    background-image: linear-gradient(to right, #fe5a77, #e70c31);
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mobile-nav-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .mobile-nav-item {
    font-size: 12px;
    background: #fff;
    padding: 3px 15px;
    margin: 0 6px 8px 0;
    border-radius: 5px;
  }
  .case-screen-active {
    
    background: #e60b31;
  }

  .case-list-box {
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 4vw;
  }
  .case-item {
    width: 44vw;
    background: #fff;
    border-radius: 10px;
    margin: 0 0 4vw 0;
    box-shadow: 0 5px 15px 0 rgba(182, 182, 182, 0.4);
    overflow: hidden;
  }
  .case-item-phone {
    height: 54vw;
    object-fit: cover;
    padding: 0;
  }
  .case-item-img {
    background-size: cover;
    mask: none;
  }
  .case-item-img-hover {
    display: none;
  }
  .case-item-desc {
    padding: 10px;
  }
  .case-item-title {
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 10px;
  }
  .case-item-label {
    margin-bottom: 0;
  }
  .case-item-label li {
    font-size: 10px;
    padding: 2px 10px;
    margin-right: 8px;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  .case-item-price {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .mobile-case-item-btn {
    display: flex;
    margin-top: 12px;
  }
  .mobile-case-item-btn .case-item-consult {
    width: 18.6vw;
    height: 25px;
    font-size: 10px;
    color: #e50027;
    line-height: 25px;
    background: #fff;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #e50027;
  }
  .mobile-case-item-btn .case-item-buy {
    width: 18.6vw;
    height: 25px;
    font-size: 10px;
    color: #fff;
    line-height: 25px;
    background: #e50027;
    margin-left: 8px;
  }

  .pop-custom {
    width: 290px;
    padding: 20px 16px 20px;
    transform: translateY(-30px);
  }
  .custom-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .custom-form-item {
    margin-bottom: 10px;
  }
  .custom-form-label {
    font-size: 10px;
    margin-right: 5px;
  }
  .custom-form-content {
    width: 188px;
    /* height: ; */
  }
  .custom-form-input {
    height: 30px;
    font-size: 10px;
    padding: 0 10px;
  }
  .custom-form-item:nth-of-type(3) {
    flex-wrap: wrap;
  }
  .custom-form-item:nth-of-type(3) .custom-form-content {
    width: 100%;
    margin-top: 10px;
  }
  .custom-form-type {
    width: 46px;
    height: 46px;
  }
  .custom-form-type-icon {
    width: 15px;
    height: 15px;
  }
  .custom-form-type>span {
    font-size: 8px;
    margin-top: 2px;
  }
  .custom-form-item:nth-of-type(5) {
  }
  .custom-form-item:nth-of-type(5) .custom-form-content {
    /* width: 100%; */
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .custom-form-budget {
    width: 60px;
    height: 25px;
    font-size: 10px;
    line-height: 24px;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .custom-form-budget-active {
    border: 1px solid #f3889a;
  }
  .custom-form-budget:last-of-type {
    width: 124px;
    
  }
  .custom-form-budget-input {
    font-size: 10px;
    padding: 0;
  }
  .custom-ercode>img {
    width: 110px;
  }
  .custom-ercode>span {
    font-size: 12px;
  }
  .custom-submit {
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 40px;
  }

  .pop-buy {
    width: 290px;
    padding: 20px 0;
  }
  .close {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240521034062d73774c6e1338456.png');
    width: 30px;
    height: 30px;
    left: 50%;
    top: auto;
    bottom: -50px;
    transform: translateX(-50%);
  }
  .buy-title {
    font-size: 16px;
  }
  .buy-ercode>img {
    width: 110px;
  }
  .buy-ercode>span {
    font-size: 12px;
  }
  .buy-submit {
    width: 160px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    border-radius: 40px;
    margin-top: 20px;
  }
}
</style>