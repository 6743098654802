<template>
  <div class="banner">
    <div class="banner-flow">
      <div class="banner-flow-rotate">
        <div class="banner-flow-column" v-for="(item, index) in bannerList" :key="index">
          <div class="banner-flow-item" v-for="(item2, index2) in item" :key="index2">
            <div class="banner-flow-item-img" :style="`background-image: url(${item2})`"></div>
          </div>
          <div class="banner-flow-item" v-for="(item2, index2) in item" :key="(index2 + 1) * 100">
            <div class="banner-flow-item-img" :style="`background-image: url(${item2})`"></div>
          </div>
        </div>
      </div>
      <img class="banner-flow-mask" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022207910f09feac131a284255.png" alt="">
    </div>
    <img class="banner-text" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022251e9a9514c3e342e851676.png">
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data() {
    return {
      bannerList: [
        // [
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024042850644293952f7007298356.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024042861da66f7ad8c0ef6963815.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/202404289e77074b4df1e139837068.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428446e39c8406d3425428760.png',
        // ],
        // [
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240527b0d92fee32b07686500791.jpg',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428fe2960bb2829ea6f213959.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024042862e6e9c84c612fab174026.png',
        //   'http://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428e122894157a6fdfc112824.png'
        // ],
        // [
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/202404283573daa86756cf1a619122.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428e8505109500259e4260063.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024042884e43cbf463ea1f3980941.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428d2669a09345311ac855702.png'
        // ],
        // [
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240527c2e5fcd43fbbccdd276786.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240527799d9f64b85b1634296540.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024042802df627f6635d5a9840929.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/2024052724c05ebc3292b085926967.png'
        // ],
        // [
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/202404285031edd80fdff09d225608.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/202404284f52d602dee23197871055.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/20240428fafe17e158c470bf319339.png',
        //   'https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/technology/202404281d0b3377e89a788f567819.png'
        // ]
      ],
    }
  },
  created() {
    this.api.caseList({
      page: 1,
      pageSize: 20,
      category: 1,
    }).then(res => {
      
      if (res.data.lists.length >= 20) {
        let result = [];
        for (let i = 0; i < res.data.lists.length; i += 4) {
          let arr = []
          res.data.lists.slice(i, i + 4).forEach(element => {
            arr.push(element.cover)
          });
          result.push(arr);
        }
        this.bannerList = result
      }
    })
  }
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: 500px;
  position: relative;
  background: #000;
}
.banner-flow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.banner-flow-rotate {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  transform: rotate(-30deg);
}
.banner-flow-column {
  margin: 0 10px 0 0;
  flex-shrink: 0;
}
.banner-flow-column:nth-of-type(1) {
  animation: move 30s linear infinite;
}
.banner-flow-column:nth-of-type(2) {
  animation: move 53s linear infinite ;
}
.banner-flow-column:nth-of-type(3) {
  animation: move 38s linear infinite;
}
.banner-flow-column:nth-of-type(4) {
  animation: move 48s linear infinite;
}
.banner-flow-column:nth-of-type(5) {
  animation: move 26s linear infinite;
}
.banner-flow-item {
  width: 360px;
  height: 710px;
  box-sizing: border-box;
  padding: 20px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240221e410bac21c06af11521858.png') center / 100% 100% no-repeat;
  /* transform: rotate(-30deg); */
  margin-top: 20px;
}
.banner-flow-item-img {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  mask: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022167dd95c144d72868211953.png') center / 100% 100% no-repeat;
}
@keyframes move {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(-70%);
  }
}
.banner-flow-mask {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
}
.banner-text {
  width: 960px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: show 2s 0.3s forwards;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 750px) {
  .banner {
    height: 50vw;
  }
  .banner-flow-item {
    width: 120px;
    height: 236px;
    padding: 10px;
    margin-top: 10px;
  }
  .banner-text {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240520a1ccf5a72f5b642f303679.png');
    width: 85%;
  }
}
</style>