<template>
  <div class="about">
    <!-- <div class="banner-box">
      <div class="banner"></div>
      <div class="banner-cover"></div>
      <div class="banner-text">
        <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240205fcadbd978408e291860094.png" alt="">
        <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240205965b70809d0101cd774796.png" alt="">
      </div>
    </div> -->
    <div class="banner-box">
      <div class="banner">
        <img class="banner-text" src="https://image.xiaoxxx.cn/test/urlGather/20240407b9eba343faafc8df578481.png">
        <div class="banner-bar"></div>
      </div>
    </div>

    <div class="introduce">
      <div class="introduce-center">
        <div class="introduce-text">
          <div class="introduce-text-title">
            <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402197f6e510468178a75255209.png" alt="">
            <p>关于小仙数字互动</p>
          </div>
          <div class="introduce-text-line" :style="introduceShow && 'width: 45px;'"></div>
          <p class="introduce-text-row">数字互动事业部聚焦营销互动技术开发，赋能品牌数字化升级。</p>
          <div class="introduce-text-block" :style="introduceShow && 'transform: scaleX(1)'">
            <img class="introduce-text-block-title" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240219a25fffb2f42b1485467807.png" alt="">
            <div class="introduce-text-block-list">
              <div class="introduce-text-block-item">
                <div class="introduce-text-block-icon">
                  <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402192c0d31496dc147dd102965.png" alt="">
                </div>
                <span>10年营销<br />互动策划经验</span>
              </div>
              <div class="introduce-text-block-item">
                <div class="introduce-text-block-icon">
                  <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240219f8e68a79cdf509aa523768.png" alt="">
                </div>
                <span>BAT级技术<br />开发验收标准</span>
              </div>
              <div class="introduce-text-block-item">
                <div class="introduce-text-block-icon">
                  <img src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240219336c06b842d6225c181961.png" alt="">
                </div>
                <span>互联网、制造业<br />场景应用全覆盖</span>
              </div>
            </div>
          </div>
          <div ref="introduce"></div>
          <p class="introduce-text-row" style="margin-bottom: 10px">“您提出需求与想法，剩下的交给我们。”</p>
          <p class="introduce-text-row" style="margin-bottom: 10px">分析策划、交互链路模拟、界面设计、技术开发、上线维护、数据分析回馈，</p>
          <p class="introduce-text-row">6大环节全面覆盖，作为您的专属顾问，提供营销互动一站式服务。</p>
        </div>
        <div class="introduce-photo">
          <img class="introduce-photo-bg" :style="introduceShow && 'transform: scale(1)'" src="https://image.xiaoxxx.cn/test/urlGather/20240407a173e12349c10300735341.png" alt="">
          <img class="introduce-photo-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402190364d3d42d503d8c608828.jpg" alt="">
        </div>
      </div>
    </div>

    <div class="service" v-fadeIn="'show'">
      <div class="about-title">
        <div>我们的服务标准</div>
        <p>OUR SERVICE STANDARDS</p>
      </div>
      <ul class="service-list" ref="service">
        <li :style="serviceShow && 'animation: show 1s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022090b2ae5684aee921403587.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">高品质的产品</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们为客户提供高品质的产品，确保客户的需求得到满足。</p>
        </li>
        <li :style="serviceShow && 'animation: show 1s 0.3s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240220f095a983ad60f7d8492885.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">优质的服务</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们提供优质的服务，确保客户能够得到及时的支持和帮助。</p>
        </li>
        <li :style="serviceShow && 'animation: show 1s 0.6s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240220e5f590ac3beadbae265646.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">合理的价格</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们提供合理的价格，确保客户能够得到性价比高的产品和服务</p>
        </li>
        <li :style="serviceShow && 'animation: show 1s 0.6s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240220182cbe459d2f9d8d779751.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">高效的响应</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们提供高效的响应服务，确保客户的问题和需求能够得到及时的解决。</p>
        </li>
        <li :style="serviceShow && 'animation: show 1s 0.9s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240220040b7c2a6e5d3bbc564131.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">严格的保密</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们严格保密客户的信息和数据，确保客户的隐私得到保护。</p>
        </li>
        <li :style="serviceShow && 'animation: show 1s 1.2s forwards;'">
          <div class="service-list-icon">
            <img class="service-list-icon-img" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402202508ac767e6ecec7296653.png">
            <img class="service-list-icon-bed1" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022049fcc159607ae9c4787333.png">
            <img class="service-list-icon-bed2" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022093e789fd3f355c14342563.png">
          </div>
          <div class="service-list-title">持续的改进</div>
          <span class="service-list-line"></span>
          <p class="service-list-desc">我们不断改进我们的产品和服务，确保客户的需求得到更好的满足。</p>
        </li>
      </ul>
    </div>

    <!-- 暂时 -->
    <div class="xiaoxxx-box clearFloat">
      <div class="video-box">
      </div>
      <div class="xiaoxxx-title-box">
        <p>“数字营销更有力，数字形象更有利”</p>
        <span></span>
        <p>杭州小仙科技有限公司致力于帮助客户打造更深入人心、更具营销力的数字品牌形象。</p>
        <p>核心团队来自国际4A、头部互联网企业，拥有丰富的互联网头部品牌、传统行业龙头品牌、前沿创新品牌服务经验。</p><p data-v-54aa25e8="">现与全球200多家互联网头部品牌、传统行业龙头品牌、前沿创新品牌建立深度合作关系。</p>
      </div>
    </div>

    <div class="activity">
      <div class="about-title">
        <div>团队活动</div>
        <p>GROUP ACTIVITIES</p>
      </div>
      <ul class="activity-list" v-fadeIn="'show'">
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024052717678ba83d4cc85f990036.jpg" alt="">
          <!-- <div class="activity-list-shade">
            <div class="activity-list-title">2023年年会大合照</div>
            <p class="activity-list-desc">介绍内容</p>
          </div> -->
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240527fe1b46ca2b5463b3269170.jpg" alt="">
          <!-- <div class="activity-list-shade">
            <div class="activity-list-title">2023年年会大合照</div>
            <p class="activity-list-desc">介绍内容</p>
          </div> -->
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240527f6cc836744457c4b902857.jpg" alt="">
          <!-- <div class="activity-list-shade">
            <div class="activity-list-title">2023年年会大合照</div>
            <p class="activity-list-desc">介绍内容</p>
          </div> -->
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/202405279ac46327753cf754285023.jpg" alt="">
          <!-- <div class="activity-list-shade">
            <div class="activity-list-title">2023年年会大合照</div>
            <p class="activity-list-desc">介绍内容</p>
          </div> -->
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240527bfea627f0580abba378387.jpg" alt="">
          <!-- <div class="activity-list-shade">
            <div class="activity-list-title">2023年年会大合照</div>
            <p class="activity-list-desc">介绍内容</p>
          </div> -->
        </li>
      </ul>
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="https://image.xiaoxxx.cn/test/urlGather/2024052717678ba83d4cc85f990036.jpg" alt="">
            <!-- <div class="activity-list-shade">
              <div class="activity-list-title">2023年年会大合照</div>
              <p class="activity-list-desc">飞盘是大人小孩儿都爱玩儿的一种游戏工具，只要有一块儿空地就能玩，而且玩法很多，比赛也有不少，估计全世界共有700多万人参加这一</p>
            </div> -->
          </div>
          <div class="swiper-slide">
            <img src="https://image.xiaoxxx.cn/test/urlGather/20240527fe1b46ca2b5463b3269170.jpg" alt="">
            <!-- <div class="activity-list-shade">
              <div class="activity-list-title">2023年年会大合照</div>
              <p class="activity-list-desc">飞盘是大人小孩儿都爱玩儿的一种游戏工具，只要有一块儿空地就能玩，而且玩法很多，比赛也有不少，估计全世界共有700多万人参加这一</p>
            </div> -->
          </div>
          <div class="swiper-slide">
            <img src="https://image.xiaoxxx.cn/test/urlGather/20240527f6cc836744457c4b902857.jpg" alt="">
            <!-- <div class="activity-list-shade">
              <div class="activity-list-title">2023年年会大合照</div>
              <p class="activity-list-desc">飞盘是大人小孩儿都爱玩儿的一种游戏工具，只要有一块儿空地就能玩，而且玩法很多，比赛也有不少，估计全世界共有700多万人参加这一</p>
            </div> -->
          </div>
          <div class="swiper-slide">
            <img src="https://image.xiaoxxx.cn/test/urlGather/202405279ac46327753cf754285023.jpg" alt="">
            <!-- <div class="activity-list-shade">
              <div class="activity-list-title">2023年年会大合照</div>
              <p class="activity-list-desc">飞盘是大人小孩儿都爱玩儿的一种游戏工具，只要有一块儿空地就能玩，而且玩法很多，比赛也有不少，估计全世界共有700多万人参加这一</p>
            </div> -->
          </div>
          <div class="swiper-slide">
            <img src="https://image.xiaoxxx.cn/test/urlGather/20240527bfea627f0580abba378387.jpg" alt="">
            <!-- <div class="activity-list-shade">
              <div class="activity-list-title">2023年年会大合照</div>
              <p class="activity-list-desc">飞盘是大人小孩儿都爱玩儿的一种游戏工具，只要有一块儿空地就能玩，而且玩法很多，比赛也有不少，估计全世界共有700多万人参加这一</p>
            </div> -->
          </div>
        </div>
        
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="address">
      <div class="about-title">
        <div>百闻不如一见</div>
        <p>SEEING IS BELIEVING</p>
      </div>
      <img class="address-map" src="https://res.xiaoxxx.com/test/m.xiaoxxx.com/202207206db0c0b17365bc64464223.png">
      <div class="address-content">
        <div class="address-content-item">
          <img src="https://res.xiaoxxx.com/test/m.xiaoxxx.com/2022072074504c55a66699c9919073.png">
          <div class="address-content-item-text">
            <div>地址:</div>
            <p>杭州市西湖区文一西路郡原公元里3幢2层</p>
          </div>
        </div>
        <div class="address-content-item">
          <img src="https://res.xiaoxxx.com/test/m.xiaoxxx.com/202207206ce262f2fc57ad06779326.png">
          <div class="address-content-item-text">
            <div>联系电话:</div>
            <p>400 0571 101</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      introduceShow: false,
      serviceShow: false,
      bannerList: [
        {
          images: 'https://image.xiaoxxx.cn/test/urlGather/202404078ade16c3a8b9345b259497.jpg'
        },
        {
          images: 'https://image.xiaoxxx.cn/test/urlGather/202404078ade16c3a8b9345b259497.jpg'
        },
      ]
    }
  },
  mounted() {
    new this.Swiper ('.swiper', {
      loop: true, // 循环模式选项
      spaceBetween: 30,
      autoplay: {
        delay: 3000,//自动轮播的间隔时间
        disableOnInteraction: false,//用户操作后是否停止自动轮播
      },
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
      },
    })

    // 监听"介绍"进入视口
    const introduceObserver = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) return;
      if (!this.complete) {
        this.introduceShow = true
      }
    });
    // 开始监听
    introduceObserver.observe(this.$refs.introduce);

    // 监听服务进入视口
    const service = new IntersectionObserver((entries) => {
        console.log(entries)
        if (entries[0].intersectionRatio <= 0) return;
        if (!this.complete) {
          console.log("Loaded new items");
          this.serviceShow = true
        }
      },
      {
        rootMargin: '10px 0px 50px 0px'
      }
    );
    // 开始监听
    service.observe(this.$refs.service);
  },
}
</script>

<style scoped>
.about {
  position: relative;
}
/* .banner-box {
  width: 100%;
  min-width: var(--maxWidth);
  height: 500px;
  overflow: hidden;
  position: relative;
}
.banner {
  width: 100%;
  height: 500px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240205b52197d422afc4c9836037.jpg') center / cover no-repeat;
  position: relative;
  mask-image: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402052f804d4f83fad57e555306.png');
  mask-position: -1000px center;
  mask-size: cover;
  mask-repeat: no-repeat;
  animation: move 1s forwards;
}
.banner::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 0, 0, 0.25);
}
.banner-cover {
  width: 100%;
  height: 500px;
  position: absolute;
  left: 0;
  top: 0;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240205b52197d422afc4c9836037.jpg');
  mask: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402058c12f12d4653e521897301.png') center/ cover;
  animation: move2 1s forwards;
}
.banner-cover::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(230, 0, 39, 0.7);
}
@keyframes move {
  0% {
    mask-position: -1000px center;
    transform: scale(1.5);
  }
  100% {
    mask-position: 0 center;
    transform: scale(1);
  }
}
@keyframes move2 {
  0% {
    mask-position: -1000px center;
    transform: scale(1.5);
  }
  100% {
    mask-position: 0 center;
    transform: scale(1);
  }
}
.banner-text {
  height: 100%;
  margin-left: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  animation: show 1s 0.5s forwards;
}
.banner-text img:first-of-type {
  height: 150px;
  margin-bottom: 65px;
}
.banner-text img:last-of-type {
  height: 35px;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
.banner-box {
  width: 100%;
  min-width: var(--maxWidth);
  height: 500px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202404078ade16c3a8b9345b259497.jpg') center / cover no-repeat;
  overflow: hidden;
  position: relative;
}
.banner {
  width: var(--maxWidth);
  height: 100%;
  margin: 0 auto;
}
.banner-text {
  width: 650px;
  margin-top: 120px;
}
.banner-bar {
  width: 0px;
  height: 55px;
  background: #e60027;
  margin-top: 40px;
  animation: elongate 0.7s forwards;
}
@keyframes elongate {
  0% {
    width: 0px;
  }
  100% {
    width: 390px;
  }
}
.banner-bar::before {
  content: '助力品牌影响力飙升';
  width: 100%;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 55px;
  letter-spacing: 10px;
  display: block;
  opacity: 0;
  animation: textShow 0.7s 0.6s forwards;
}
@keyframes textShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.introduce {
  width: 100%;
  background: #fff;
  padding: 90px 0;
}
.introduce-center {
  width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.introduce-text {

}
.introduce-text-title {
  font-size: 34px;
  color: #e60027;
  position: relative;
}
.introduce-text-title img {
  width: 105px;
  position: absolute;
  left: -56px;
  bottom: 0;
}
.introduce-text-line {
  width: 10px;
  height: 2px;
  background: #ee848f;
  margin: 26px 0;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 2.2);
}
.introduce-text-row {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}
.introduce-text-block {
  width: 530px;
  background: linear-gradient(to right, #fff4f4, #fffdfd);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 30px 30px 40px;
  margin: 25px 0;
  transition: all 0.5s;
  transform: scaleX(0);
  transform-origin: 0 0;
}
.introduce-text-block-title {
  height: 20px;
  margin-bottom: 32px;
}
.introduce-text-block-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.introduce-text-block-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduce-text-block-icon {
  width: 52px;
  height: 52px;
  background: #fcdbdf;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.introduce-text-block-icon img {
  width: 24px;
}
.introduce-text-block-item span {
  font-size: 14px;
  color: #585858;
}
.introduce-photo {
  position: relative;
}
.introduce-photo-bg {
  width: 360px;
  transform: scale(1.25);
  transition: all 0.5s;
}
.introduce-photo-img {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.about-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}
.about-title>div {
  font-size: 30px;
  color: #252525;
  position: relative;
}
.about-title>div::before {
  content: '';
  width: 40px;
  height: 15px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402200ce688622e028ae0233853.png') center / contain no-repeat;
  position: absolute;
  left: -55px;
  bottom: 0;
}
.about-title>div::after {
  content: '';
  width: 40px;
  height: 15px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022016fd1e6c35811a53747660.png') center / contain no-repeat;
  position: absolute;
  right: -55px;
  top: 5px;
}
.about-title>p {
  font-size: 20px;
  color: rgba(37, 37, 37, 0.25);
  letter-spacing: 2px;
  margin-top: 18px;
}

.service {
  width: 100%;
  background: #f9f9f9 url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022005ff00f0734b786b196486.png') center / cover no-repeat;
  padding: 60px 0;
}
.service-list {
  width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.service-list>li {
  width: 305px;  
  height: 400px;
  background: #fff;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 60px;
  opacity: 0;
}
.service-list-icon {
  width: 155px;
  height: 155px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-list-icon>img {
  position: absolute;
  transition: all 0.5s;
}
.service-list-icon-img {
  width: 82px;
  bottom: 50px;
  z-index: 3;
}
.service-list-icon-bed1 {
  width: 156px;
  bottom: 10px;
  z-index: 2;
}
.service-list-icon-bed2 {
  width: 156px;
  bottom: 0;
  z-index: 1;
}
.service-list>li:hover .service-list-icon-img {
  transform: translateY(-20%) scale(1.1);
}
.service-list>li:hover .service-list-icon-bed1 {
  transform: translateY(-10%);
}
.service-list-title {
  font-size: 22px;
  color: #000;
  margin-top: 30px;
}
.service-list-line {
  width: 18px;
  height: 1px;
  display: block;
  background: #000;
  margin: 15px 0;
}
.service-list-desc {
  width: 260px;
  font-size: 14px;
  color: rgba(24, 8, 0, 0.6);
}

.activity {
  width: 100%;
  padding: 60px 0;
}
.swiper {
  display: none;
}
.activity-list {
  width: var(--maxWidth);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, 320px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  
}
.activity-list>li {
  background: #fff;
  position: relative;
  overflow: hidden;
}
.activity-list>li:first-of-type {
  grid-column-start: 1;
  grid-column-end: 3;
}
.activity-list>li>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
  transition: all 2s;
}
.activity-list-shade {
  width: 100%;
  height: 120px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.57));
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  padding: 25px;
  /* transform: translateY(120px); */
  opacity: 0;
  transition: all 0.6s;
}
.activity-list-title {
  font-size: 30px;
  color: #fff;
}
.activity-list-desc {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}
.activity-list>li:hover>img {
  transform: scale(1.2);
}
.activity-list>li:hover .activity-list-shade{
  opacity: 1;
  /* transform: translateY(0); */
}
.address {
  margin-bottom: 50px;
}
.address-map {
  width: var(--maxWidth);
  margin: 0 auto;
  display: block;
  border-radius: 15px;
}
.address-content {
  width: var(--maxWidth);
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address-content-item {
  width: 40%;
  display: flex;
  align-items: center;
}
.address-content-item>img {
  width: 25px;
  display: block;
  margin-right: 20px;
}
.address-content-item-text {
  font-size: 12px;
}
.address-content-item-text>p {
  font-size: 16px;
  font-weight: 700;
  margin-top: 5px;
}

/* 暂时 */
.xiaoxxx-box {
  width: var(--maxWidth);
  margin: 60px auto 0;
  position: relative;
}
.video-box {
  width: 550px;
  height: 326px;
  overflow: hidden;
  border: 5px solid #fff;
  box-shadow: 0 0 20px #f3f3f3;
  border-radius: 10px;
  position: relative;
  background: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/202207277e4d49bf6822e1d7713464.jpg') no-repeat 50%;
  background-size: 100%;
}
.xiaoxxx-title-box {
  /* width: 470px; */
  height: 378px;
  background: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/20220720cb94efebd9a5a7ac591873.png') center top / 100% no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 585px;
  box-sizing: border-box;
  padding: 24px 0 0 21px;

}
.xiaoxxx-title-box p {
  font-size: 13px;
  color: rgba(0, 0, 0, .7);
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
}
.xiaoxxx-title-box p:first-of-type {
  font-size: 21px;
  color: #020202;
  font-weight: 700;
  white-space: nowrap;
}
.xiaoxxx-title-box span {
  display: block;
  width: 32px;
  height: 4px;
  background-color: #ee172e;
  margin: 26px 0 29px;
}

@media screen and (max-width: 750px) {
  .banner-box {
    min-width: 0;
    height: 200px;
  }
  .banner {
    width: 100%;
  }
  .banner-text {
    width: 300px;
    display: block;
    margin: 40px auto 0;
    content: url('https://image.xiaoxxx.cn/test/urlGather/202405178c266f17933a24ff353534.png');
  }
  .banner-bar {
    height: 30px;
    margin: 12px auto 0;
    overflow: hidden;
    animation: elongate-mobile 0.7s 0.6s forwards;
  }
  @keyframes elongate-mobile {
    0% {
      width: 0px;
    }
    100% {
      width: 160px;
    }
  }
  .banner-bar::before {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  .introduce {
    padding: 30px 0;
  }
  .introduce-center {
    width: 100%;
    flex-direction: column;
  }
  .introduce-text {
    width: 100%;
  }
  .introduce-text-title {
    font-size: 18px;
    font-weight: 600;
    color: #020202;
    box-sizing: border-box;
    padding: 0 40px;
  }
  .introduce-text-title img {
    width: 30px;
    left: 30px;
    bottom: ;
  }
  .introduce-text-line {
    margin: 16px 0 16px 40px;
  }
  .introduce-text-row {
    font-size: 11px;
    box-sizing: border-box;
    padding-left: 40px;
  }
  .introduce-text-block {
    width: 100%;
    padding: 20px 40px;
    margin: 20px 0;
  }
  .introduce-text-block-title {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240517202c0e8366f4386d266926.png');
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .introduce-text-block-item span {
    font-size: 10px;
  }
  .introduce-photo {
    margin: 35px auto 20px;
    box-shadow: 0 0 45px 5px rgba(0, 0, 0, 0.13);
  }
  .introduce-photo-bg {
    width: 84vw;
    height: 49vw;
    content: url('https://image.xiaoxxx.cn/test/urlGather/202405179285995c813bc72c476524.png');
    margin-top: -15px;
  }
  .introduce-photo-img {
    width: 92vw;
    height: 50.6vw;
    box-sizing: border-box;
    border: 5px solid #fff;
    border-radius: 10px;
    object-fit: cover;
  }

  .service{
    padding: 30px 0;
  }
  .about-title {
    margin-bottom: 20px;
  }
  .about-title>div {
    font-size: 18px;
  }
  .about-title>div::before {
    width: 6.6vw;
    left: -9.3vw;
  }
  .about-title>div::after {
    width: 6.6vw;
    right: -9.3vw;
  }
  .about-title>p {
    font-size: 12px;
    text-align: center;
    letter-spacing: 0;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0 8vw;
  }

  .service-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 4vw;
  }
  .service-list>li {
    width: 44vw;
    height: 47vw;
    padding-top: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .service-list-icon {
    width: 17.3vw;
    height: 17.3vw;
  }
  .service-list-icon-img {
    width: 9.3vw;
    bottom: 25px;
  }
  .service-list-icon-bed1 {
    width: 17.3vw;
    bottom: 6px;
  }
  .service-list-icon-bed2 {
    width: 17.3vw;
  }
  .service-list-title {
    font-size: 14px;
    margin-top: 10px;
  }
  .service-list-line {
    width: ;
    margin: 6px 0;
    background: #ee172e;
  }
  .service-list-desc {
    width: 85%;
    font-size: 10px;
    text-align: center;
  }

  .activity {
    padding: 30px 0 40px;
    background: #181818;
  }
  .activity .about-title>div {
    color: #fff;
  }
  .activity .about-title>p {
    color: rgba(255, 255, 255, 0.5);
  }
  .activity .about-title>div::before {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405164d3188326ff67300732881.png');
  }
  .activity .about-title>div::after {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405167169c49dcd2d9e5e442922.png');
  }
  .activity-list {
    display: none;
  }
  .swiper {
    width: 80vw;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
  }
  .swiper-slide {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .swiper-slide>img {
     width: 100%;
     height: 73.3vw;
     display: block;
     object-fit: cover;
  }
  .activity-list-shade {
    height: 90px;
    padding: 12px;
    position: relative;
    opacity: 1;
    background: #fff
  }
  .activity-list-title {
    font-size: 12px;
    color: #050505;
    font-weight: 600;
  }
  .activity-list-desc {
    font-size: 10px;
    color: rgba(5, 5, 5, 0.6);
    margin-top: 10px;
  }
  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet {
    width: 25px!important;
  }

  .address {
    padding: 30px 0;
    margin-bottom: 0;
    background: #181818;
  }
  .address .about-title>div {
    color: #fff;
  }
  .address .about-title>p {
    color: rgba(255, 255, 255, 0.5);
  }
  .address .about-title>div::before {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405164d3188326ff67300732881.png');
  }
  .address .about-title>div::after {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405167169c49dcd2d9e5e442922.png');
  }
  .address-map {
    width: calc(100% - 8vw);
    content: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/20220803ada8cc6b9cd1a8ad289871.jpg');
  }
  .address-content {
    width: calc(100% - 8vw);
    color: #fff;
    align-items: flex-start;
  }
  .address-content-item {
    width: 45%;
    align-items: flex-start;
  }
  .address-content-item>img {
    width: 20px;
    margin-top: 7px;
  }
  .address-content-item-text {
    font-size: 10px;
  }
  .address-content-item-text>p {
    font-size: 12px;
  }

  /* 暂时 */
  .xiaoxxx-box {
    width: calc(100% - 8vw);
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 20px;
  }
  .video-box {
    width: 100%;
    height: 190px;
  }
  .xiaoxxx-title-box {
    height: auto;
    padding: 15px 10px;
    margin-top: 20px;
    position: static;
    left: 0;
    background: url('https://res.xiaoxxx.com/test/m.xiaoxxx.com/2022080322e73efbac3239a9902393.png') center top / 100% no-repeat;
  }
  .xiaoxxx-title-box p {
    font-size: 12px;
    margin-bottom: 12px;
  }
  .xiaoxxx-title-box p:first-of-type {
    font-size: 16px;
  }
  .xiaoxxx-title-box span {
    width: 28px;
    margin: 15px 0 15px;
  }
}
</style>
