<template>
  <div class="main" v-fadeIn="'show'">
    <div class="advantage-content">
      <div class="advantage-row">
        <div class="advantage-item">
          <div class="advantage-item-title">丰富的行业经验</div>
          <div class="advantage-item-desc">
            <p>
              我们拥有多年的技术开发经验，与阿里巴巴等大厂有着深度合作。为客户提供了众多成功的营销案例。能够从市场趋势、用户需求、美术风格等多方面进行深入分析，为客户提供全方位的定制化服务。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/20240522d6e229403a6ab802813154.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">高度优化的性能表现</div>
          <div class="advantage-item-desc">
            <p>
              在项目开发过程中，我们充分考虑手机性能和加载速度，通过代码优化、资源压缩等技术手段，确保项目在各种设备和网络环境下都能流畅运行，带给用户最佳的体验。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/202403199337c9e799327c92133241.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">跨平台兼容性</div>
          <div class="advantage-item-desc">
            <p>
              H5 开发可以让我们的产品兼容 PC 端、移动端、Windows、Linux、安卓和 iOS 等多种平台，实现一次开发多平台部署，节省开发成本和时间。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/202403191a43b994cd1662b3584237.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">不断探索新技术</div>
          <div class="advantage-item-desc">
            <p>
              我们紧跟行业发展趋势，不断研究和掌握新技术，为客户提供更具创新性和技术领先的定制化服务。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/20240319b9e530dbe273c6b3542734.png)"></div>
        </div>
      </div>
      <div class="advantage-row">
        <div class="advantage-item">
          <div class="advantage-item-title">良好的用户体验</div>
          <div class="advantage-item-desc">
            <p>
              我们注重用户体验设计，将用户需求和体验贯穿于整个开发过程，以满足用户需求为导向，提供符合用户使用习惯和喜好的产品。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/2024031994e36783668a7787751138.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">高效的团队协作</div>
          <div class="advantage-item-desc">
            <p>
              我们的团队成员具备良好的沟通和协作能力，能够高效地完成项目任务，确保项目质量和进度。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/202403190ef4ce47dd27cc9d246935.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">优质的售后服务</div>
          <div class="advantage-item-desc">
            <p>
              我们为客户提供持续的售后服务，包括技术支持、问题修复和版本更新等，确保客户在使用过程中无后顾之忧。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/202403192a97e862e95f7c61816962.png)"></div>
        </div>
        <div class="advantage-item">
          <div class="advantage-item-title">7×24小时随时响应</div>
          <div class="advantage-item-desc">
            <p>
              我们提供 24 小时全天候的服务，随时响应客户的问题和需求，确保客户能够得到及时的支持和帮助。
            </p>
          </div>
          <div class="advantage-item-bg" style="background-image: url(https://image.xiaoxxx.cn/test/urlGather/2024031934d4979f1485b480992669.png)"></div>
        </div>
      </div>
    </div>
    <div class="mobile-advantage-content">
      <ul>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240522cc85e1926ef68216375222.png">
          <div class="mobile-advantage-title">丰富的行业经验</div>
          <p class="mobile-advantage-desc">我们拥有多年的技术开发经验，与阿里巴巴等大厂有着深度合作。为客户提供了众多成功的营销案例。能够从市场趋势、用户需求、美术风格等多方面进行深入分析，为客户提供全方位的定制化服务。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240522fac3176f54f8ebf1750455.png">
          <div class="mobile-advantage-title">高度优化的性能表现</div>
          <p class="mobile-advantage-desc">在项目开发过程中，我们充分考虑手机性能和加载速度，通过代码优化、资源压缩等技术手段，确保项目在各种设备和网络环境下都能流畅运行，带给用户最佳的体验。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240515396cdbad1c1af33d804006.png">
          <div class="mobile-advantage-title">跨平台兼容性</div>
          <p class="mobile-advantage-desc">H5 开发可以让我们的产品兼容 PC 端、移动端、Windows、Linux、安卓和 iOS 等多种平台，实现一次开发多平台部署，节省开发成本和时间。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240515d5139094415ba2a9231147.png">
          <div class="mobile-advantage-title">不断探索新技术</div>
          <p class="mobile-advantage-desc">我们紧跟行业发展趋势，不断研究和掌握新技术，为客户提供更具创新性和技术领先的定制化服务。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/202405154f5e14915bb7cf9c758413.png">
          <div class="mobile-advantage-title">良好的用户体验</div>
          <p class="mobile-advantage-desc">我们注重用户体验设计，将用户需求和体验贯穿于整个开发过程，以满足用户需求为导向，提供符合用户使用习惯和喜好的产品。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240522d42e41ba1ec3c2b7886062.png">
          <div class="mobile-advantage-title">高效的团队协作</div>
          <p class="mobile-advantage-desc">我们的团队成员具备良好的沟通和协作能力，能够高效地完成项目任务，确保项目质量和进度。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024051526a8cb94b9f30679759854.png">
          <div class="mobile-advantage-title">优质的售后服务</div>
          <p class="mobile-advantage-desc">我们为客户提供持续的售后服务，包括技术支持、问题修复和版本更新等，确保客户在使用过程中无后顾之忧。</p>
        </li>
        <li>
          <img src="https://image.xiaoxxx.cn/test/urlGather/202405152f1f66e74a8768c1769748.png">
          <div class="mobile-advantage-title">7×24小时随时响应</div>
          <p class="mobile-advantage-desc">我们提供 24 小时全天候的服务，随时响应客户的问题和需求，确保客户能够得到及时的支持和帮助。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.advantage-content {
  width: var(--maxWidth);
  margin: 20px auto;
}
.mobile-advantage-content {
  display: none;
}
.advantage-row {
  display: flex;
}
.advantage-row:last-child {
  border-top: 1px solid #e3e3e3;
}
.advantage-item {
  height: 160px;
  border-right: 1px solid #e3e3e3;
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  transition: all 0.3s;
  position: relative;
}
/* .advantage-item::before {
  content: '';
  width: 100%;
  height: 100%;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403111b0dfbe4c894b614916934.png') right bottom / 100px no-repeat;
  position: absolute;
  left: 0;
  top: 0;
} */
.advantage-item-bg {
  width: 100%;
  height: 100%;
  /* background: url('https://image.xiaoxxx.cn/test/urlGather/202403111b0dfbe4c894b614916934.png') right bottom / 100px no-repeat; */
  background-position: right bottom;
  background-size: 100px;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}
.advantage-item:last-child {
  border-right: none;
}
.advantage-item-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
}
.advantage-item-desc {
  width: 0px;
  font-size: 14px;
  color: rgba(24, 8, 0, 0.5);
  line-height: 22px;
  margin-top: 15px;
  transition: all 0.2s;
  opacity: 0;
  overflow: hidden;
}
.advantage-item-desc>p {
  width: 330px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
.advantage-item:hover {
  flex: 2;
  background-image: linear-gradient(#fa1c42, #ff5370, #ff4464);
}
.advantage-item:hover .advantage-item-title {
  color: #fff;
}
.advantage-item:hover .advantage-item-desc {
  width: 330px;
  color: #fff;
  opacity: 1;
}
@media screen and (max-width: 750px) {
  .advantage-content {
    display: none;
  }
  .mobile-advantage-content {
    display: block;
  }
  .mobile-advantage-content ul {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 4vw;
  }
  .mobile-advantage-content li {
    width: 44vw;
    height: 44vw;
    margin-bottom: 15px;
    box-shadow: 0 5px 15px 0 rgba(182, 182, 182, 0.3);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  .mobile-advantage-content li img {
    width: 22.6vw;
    height: 22.6vw;
    object-fit: contain;
    display: block;
    position: absolute;
    left: 50%;
    top: 18%;
    transform: translateX(-50%);
    transition: all 0.3s;
  }
  .mobile-advantage-title {
    width: 100%;
    font-size: 12px;
    color: #000;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 75%;
    transition: top 0.3s;
  }
  .mobile-advantage-desc {
    height: 100%;
    font-size: 10px;
    color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 50px 4vw 0;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .mobile-advantage-content li:hover {
    background: rgba(245, 35, 61, 0.6);
    box-shadow: 0 5px 15px 0 rgba(229, 0, 39, 0.4);
  }
  .mobile-advantage-content li:hover img {
    left: auto;
    top: auto;
    right: -5vw;
    bottom: -3vw;
    transform: translateX(0);
  }
  .mobile-advantage-content li:hover .mobile-advantage-title {
    font-size: 16px;
    color: #fff;
    top: 10%;
    z-index: 3;
  }
  .mobile-advantage-content li:hover .mobile-advantage-desc {
    background: rgba(245, 35, 61, 0.6);
    opacity: 1;
    position: relative;
    z-index: 2;
  }
}
</style>