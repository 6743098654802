<template>
  <div class="news-list">
    <div class="news-content">
      <ul>
        <li class="news-item" v-for="item in listData" :key="item.id" v-fadeIn="'show'">
          <img class="news-img" :src="item.cover">
          <div class="news-info">
            <div class="news-title">{{item.title}}</div>
            <p class="news-desc">{{item.introduction}}</p>
          </div>
          <div class="news-date">
            <div class="news-date-year">{{item.created_at.substring(0, 4)}}</div>
            <div class="news-date-day">{{item.created_at.substring(5, 10)}}</div>
            <div class="news-date-btn" @click="toDetail(item.id)">查看详情</div>
          </div>
        </li>
      </ul>
      <div class="pagination">
        <div class="prev" :style="page <= 1 && 'background: #cdd0d4'" @click="pageChange('prev')"></div>
        <div class="num-item" v-if="page > 3" @click="pageChange(1)">1</div>
        <div class="num">
          <template v-for="(item, index) in Math.ceil(count / pageSize)">
            <span
              v-if="Math.abs(page - item) < 3"
              :class="page == item ? 'num-item num-item-active' : 'num-item'"
              :key="index"
              @click="pageChange(item)"
            >
              {{item}}
            </span>
            <span v-else class="num-item num-item-omit" :key="index">
              ...
            </span>
          </template>
        </div>
        <div class="num-item" v-if="page < Math.ceil(count / pageSize) - 2" @click="pageChange(Math.ceil(count / pageSize))">{{Math.ceil(count / pageSize)}}</div>
        <div class="next" :style="page >= (count / pageSize) && 'background: #cdd0d4'" @click="pageChange('next')"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [],
      page: 1,
      pageSize: 10,
      count: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.api.newsList({page: this.page, pageSize: this.pageSize}).then(res => {
        this.listData = res.data.lists
        this.count = res.data.count
      })
    },
    pageChange(val) {
      if (val === 'prev') {
        if (this.page > 1) {
          this.page -= 1
        }
      }
      if (val === 'next') {
        if (this.page < (this.count / this.pageSize)) {
          this.page += 1
        }
      } 
      if (typeof val === 'number') {
        this.page = val
      }
      this.getList()
    },
    toDetail(id) {
      this.$router.push({name: 'newsDetail', query: {id: id}})
    }
  }
}
</script>

<style scoped>
.news-list {
  width: 100%;
}
.news-content {
  width: var(--maxWidth);
  margin: 0 auto;
}
.news-content ul {
  margin: 80px 0 100px;
}
.news-item {
  width: 100%;
  height: 160px;
  box-sizing: border-box;
  padding: 20px 0;
  border-top: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.news-item:last-child {
  border-bottom: 1px solid #f2f2f2;
}
.news-img {
  width: 230px;
  height: 120px;
  object-fit: cover;
  display: block;
  flex-shrink: 0;
  border-radius: 12px;
}
.news-info {
  width: 800px;
  height: 100%;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.news-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.96);
  margin: 15px 0;
}
.news-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-date {
  box-sizing: border-box;
  padding: 12px;
}
.news-date-year {
  width: 45px;
  height: 16px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  line-height: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.news-date-day {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}
.news-date-btn {
  width: 94px;
  height: 32px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  background: #e60b31;
  border-radius: 32px;
  margin-top: 12px;
  cursor: pointer;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px 0 100px;
}
.prev {
  width: 15px;
  height: 15px;
  background: #000;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/202403145dff06351b141254376352.png') center / contain no-repeat;
  margin: 0 20px;
  cursor: pointer;
}
.next {
  width: 15px;
  height: 15px;
  background: #000;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/2024031438346b42a17ab7f4387096.png') center / contain no-repeat;
  margin: 0 20px;
  cursor: pointer;
}
.num {
  display: flex;
}
.num-item {
  color: rgba(0, 0, 0, 0.5);
  margin: 0 20px;
  cursor: pointer;
}
.num-item-active {
  color: rgba(0, 0, 0, 1);
}
.num-item-omit {
  display: none;
}
.num-item-omit:first-child {
  display: block;
  cursor: no-drop;
  /* position: relative;
  opacity: 1; */
}
.num-item-omit:last-child {
  display: block;
  cursor: no-drop;
  /* position: relative;
  opacity: 1; */
}
@media screen and (max-width: 750px) {
  .news-content {
    width: 100%;
  }
  .news-content ul {
    box-sizing: border-box;
    padding: 0 4vw;
    margin: 20px 0 30px;
  }
  .news-item {
    height: 25.3vw;
    padding: 15px 0;
    justify-content: space-between;
    position: relative;
  }
  .news-img {
    width: 26vw;
    height: 17.3vw;
    border-radius: 8px;
    flex-shrink: 0;
  }
  .news-info {
    width: 100%;
    margin-left: 3.2vw;
  }
  .news-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-desc {
    font-size: 10px;
    -webkit-line-clamp: 2;
  }
  .news-date {
    height: 100%;
    padding: 0 0 0 13px;
    display: flex;
    align-items: flex-end;
  }
  .news-date-year, .news-date-day {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1;
    border: none;
    margin: 0;
    position: absolute;
    left: 0;
  }
  .news-date-year {
    left: 27.2vw;
    top: 18.1vw;
  }
  .news-date-year::after {
    content: '-';
  }
  .news-date-day {
    left: calc(27.2vw + 36px);
    top: 18.1vw;
  }
  .news-date-btn {
    width: 72px;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
  }
  .pagination {
    margin: 0 0 30px;
  }
}
</style>