<template>
  <div class="main">
    <div class="news-content" :style="listData.length <= 1 ? 'justify-content: center;' : ''">
      <div class="news-item" @click="routerTo('newsDetail', {id: item.id})" v-for="(item, key) in listData" :key="key">
        <div class="news-item-date">
          <div class="news-item-date-day">{{item.created_at.substring(8, 10)}}</div>
          <div class="news-item-date-year">{{item.created_at.substring(0, 7)}}</div>
        </div>
        <div class="news-item-text">
          <div class="news-item-title">{{item.title}}</div>
          <p class="news-item-desc">{{item.introduction}}</p>
        </div>
      </div>
      <div class="news-item-btn">
        <div class="news-more" @click="routerTo('News')">
          更多资讯
          <span class="news-more-arrows"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['routerTo'],
  data() {
    return {
      listData: []
    }
  },
  created() {
    this.api.newsList({page: 1, pageSize: 10}).then(res => {
      this.listData = res.data.lists
    })
  }
}
</script>

<style scoped>
.news-content {
  width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.news-item {
  width: 480px;
  height: 100px;
  background: #fff;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}
.news-item-date {
  width: 70px;
  height: 70px;
  background: #f3f3f3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.3s;
}
.news-item-date-day {
  height: 50px;
  font-size: 40px;
  color: #aaaaaa;
  text-align: center;
  line-height: 50px;
  transition: all 0.3s;
}
.news-item-date-year {
  height: 20px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  background: #cccccc;
  transition: all 0.3s;
}
.news-item-text {
  width: 365px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.news-item-title {
  font-size: 18px;
  color: #000;
}
.news-item-desc {
  font-size: 12px;
  color: rgba(37, 37, 37, 0.6);
  line-height: 22px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-item:hover {
  box-shadow: 0 6px 20px 0 #eeeff6;
  z-index: 2;
}
.news-item:hover .news-item-date {
  background: #e50027;
}
.news-item:hover .news-item-date-day {
  color: #fff;
}
.news-item:hover .news-item-date-year {
  color: #fff;
  background: #ff4565;
}
.news-item:hover .news-item-title {
  color: #e50027;
}
.news-item:hover .news-item-desc {
  color: rgba(37, 37, 37, 1);
}
.news-item-btn {
  width: 100%;
  margin: 0 auto;
}
.news-more {
  width: 300px;
  height: 60px;
  font-size: 20px;
  /* color: #252525; */
  color: transparent;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  border-radius: 60px;
  margin: 40px auto 0;
  position: relative;
  background: linear-gradient(to right, #e60027 0px, #e60027 300px, #000 300px, #000 800px) -300px / 800px 100% no-repeat;
  background-clip: text;
  cursor: pointer;
  transition: all 0.5s;
}
.news-more::before {
  content: '';
  width: 300px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background: linear-gradient(-45deg, #000 0px, #000 300px, #e60027 300px, #e60027 800px) -450px / 800px 100% no-repeat; */
  background: linear-gradient(-45deg, #000 0px, #000 300px, #fff 300px, #fff 310px, #e60027 310px, #e60027 800px) -450px / 800px 100% no-repeat;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/2024031266284070493b1e7a411910.png') center / contain no-repeat;
  transition: all 1s;
}
.news-more-arrows {
  width: 30px;
  height: 15px;
  display: inline-block;
  background: linear-gradient(to right, #e60027 0px, #e60027 30px, #000 30px, #000 80px) -40px / 80px 100% no-repeat;;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/2024031289683e1f6f6f755b320110.png') center / contain no-repeat;
  transition: all 0.5s;
}
.news-more:hover {
  color: transparent;
  background: linear-gradient(to right, #e60027 0px, #e60027 300px, #000 300px, #000 800px) 0px / 800px 100% no-repeat;
  background-clip: text;
}
.news-more:hover::before {
  /* background: linear-gradient(-45deg, #000 0px, #000 300px, #e60027 300px, #e60027 800px) 0px / 800px 100% no-repeat; */
    background: linear-gradient(-45deg, #000 0px, #000 300px, #fff 300px, #fff 310px, #e60027 310px, #e60027 800px) 0px / 800px 100% no-repeat;
}
.news-more:hover .news-more-arrows {
  background: linear-gradient(to right, #e60027 0px, #e60027 30px, #000 30px, #000 80px) 0px / 80px 100% no-repeat;
}

@media screen and (max-width: 750px)  {
  .news-content {
    width: 92vw;
    border-radius: 20px;
    background: #fff;
  }
  .news-item {
    width: 100%;
    height: auto;
    margin: 0;
    align-items: flex-start;
    padding: 4vw 15px;
  }
  .news-item:hover {
    box-shadow: 0 0 20px 0 #eeeff6;
    border-radius: 20px;
  }
  .news-item::after {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background: #f2f2f2;
  }
  .news-item:first-of-type {
    border-radius: 20px 20px 0 0;
  }
  .news-item:last-of-type {
    border-radius: 0 0 20px 20px;
  }
  .news-item:last-of-type::after {
    height: 0;
  }
  .news-item-date {
    width: 10vw;
    height: 10vw;
    min-width: 45px;
    min-height: 45px;
    background: #e50027;
  }
  .news-item-date-day {
    height: 30px;
    font-size: 20px;
    color: #fff;
    line-height: 9vw;
  }
  .news-item-date-year {
    height: 12px;
    font-size: 8px;
    color: #fff;
    line-height: 12px;
    background: #ff4565;
  }
  .news-item-text {
    width: 70vw;
  }
  .news-item-title {
    font-size: 14px;
  }
  .news-item-desc {
    font-size: 10px;
    line-height: 14px;
    margin-top: 5px;
    -webkit-line-clamp: 3;
  }
  .news-item-btn {
    width: 100%;
    padding: 0 0 20px;
  }
  .news-more {
    width: 180px;
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    margin: 20px auto 0;
  }
  .news-more::before {
    width: 180px;
    height: 40px;
  }
  .news-more-arrows {
    width: 15px;
    height: 9px;
  }
}
</style>