import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Case from '../views/Case.vue'
import News from '../views/news/News.vue'
import NewsList from '../views/news/NewsList.vue'
import NewsDetail from '../views/news/NewsDetail.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/case',
    name: 'Case',
    component: Case
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    redirect: '/news/list',
    children: [
      {
        path: '/news/list',
        name: 'newsList',
        component: NewsList
      },
      {
        path: '/news/detail',
        name: 'newsDetail',
        component: NewsDetail
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // window.scrollTo({
  //   top: 0, 
  //   behavior: 'smooth'
  // });
  scrollTo(0,0)
  next()
})

// router.afterEach((to, from) => {
//   console.log(to, from)
//   window.scrollTo({
//     top: 0, 
//     behavior: 'smooth'
//   });
// })

export default router
