<template>
  <div id="app">
    <Header></Header>
    <!-- <video class="cover-video" ref="coverVideo" @ended="videoEnded" autoplay="autoplay" data-inline-video="" muted="muted" preload="none" aria-label="动视光魔" playsinline="" webkit-playsinline src="https://image.xiaoxxx.cn/test/urlGather/2024042446551b1727a10797761690.mp4"></video> -->
    <!-- <video class="mobile-cover-video" @play="mobileVideoTips = false" ref="mobileCoverVideo" @ended="videoEnded" autoplay="autoplay" data-inline-video="" muted="muted" preload="none" aria-label="动视光魔" playsinline="" webkit-playsinline src="https://image.xiaoxxx.cn/test/urlGather/20240521b67ab03dd554dddd981639.mp4"></video> -->
    <!-- <img class="android-icon" v-if="androidIcon" @click="videoPlay" src="https://image.xiaoxxx.cn/test/urlGather/20240719d7f6c88c0360709b202271.png"> -->
    <!-- <div class="skip" v-show="skipShow" @click="coverSkip">跳过</div> -->
    <!-- <div class="mobile-video-tips" v-if="mobileVideoTips && skipShow">点击播放按钮，开始了解我们</div> -->
    <transition name="switch">
      <router-view/>
    </transition>
    <Footer></Footer>
  </div>
</template>
<script>
// 云雀客服
(function(y, un, q, u, e) {
  window[un] =
    window[un] ||
    function(y) {
      window[un].company_id = window[un].company_id || y;
      (window[un].sid  = '3808708380490659');
    };
    u = document.createElement("script");
    u.src = q + "?v=" + new Date().getUTCDate();
    u.async = true;
    e = document.getElementsByTagName("script")[0];
    e.parentNode.insertBefore(u, e);
})(window,"_YUNQUE","//dist.yunque360.com/bundle.js");
window._YUNQUE("ckb86vluetkp5h");

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  components: {
    Footer,
    Header
  },
  data() {
    return {
      // skipShow: true,
      // mobileVideoTips: false,
      // androidIcon: false
    }
  },
  mounted() {
    // const coverVideo = document.getElementsByClassName("mobile-cover-video")[0]
    // document.addEventListener("WeixinJSBridgeReady", function() {
    //   setTimeout(() => {
    //     coverVideo.play();
    //   }, 100);
    // }, false)
    // function isVideoPlaying() {
    //   return !!(coverVideo.currentTime > 0 && !coverVideo.paused && !coverVideo.ended && coverVideo.readyState > 2);
    // }
    // setTimeout(() => {
    //   if (!isVideoPlaying()) {
    //     this.mobileVideoTips = true
    //     if(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('HarmonyOS') > -1 || navigator.userAgent.indexOf('Linux') > -1) {
    //       this.androidIcon = true
    //     }
    //   }
    // }, 1500)
    // coverVideo.addEventListener('timeupdate', function() {
    //   if (this.mobileVideoTips) {
    //     this.mobileVideoTips = false
    //   }
    // });
  },
  methods: {
    // videoPlay() {
    //   this.$refs.mobileCoverVideo.play()
    //   this.androidIcon = false
    // },
    // coverSkip() {
    //   this.$refs.coverVideo.pause()
    //   this.$refs.mobileCoverVideo.pause()
    //   this.videoEnded()
    // },
    // videoEnded() {
    //   this.$refs.coverVideo.style.opacity = 0
    //   this.$refs.mobileCoverVideo.style.opacity = 0
    //   this.skipShow = false
    //   setTimeout(() => {
    //     this.$refs.coverVideo.style.display = 'none'
    //     this.$refs.mobileCoverVideo.style.display = 'none'
    //   }, 500);
    // },
  }
}
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}
body {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}
body::-webkit-scrollbar {
  display: none;
}
:root {
  --maxWidth: 960px;
}
p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
#app {
  font-family: 'PingFang SC', 'Microsoft Yahei', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  box-sizing: border-box;
  padding-top: 62px;
  overflow: hidden;
}
.cover-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  transition: all 0.5s;
}
.mobile-cover-video {
  display: none;
}
.android-icon {
  width: 60px;
  box-sizing: border-box;
  padding: 8px 6px 8px 10px;
  background: #333;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.skip {
  width: 60px;
  height: 28px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  background: rgba(100, 100, 100, 0.6);
  border-radius: 25px;
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000;
}
.mobile-video-tips {
  display: none;
}
/* 
.switch-enter {
  filter: blur(500px);
}
.switch-leave {
  filter: blur(500px);
} */
.switch-enter-active {
  /* position: absolute; */
  transform-origin: center 200px;
  animation: switch-in 0.5s linear;
}
.switch-leave-active {
  /* position: absolute; */
  /* animation: switch-in 1s ; */
}
@keyframes switch-in {
  /* 0% {
    filter: blur(0px) opacity(100%);
  } */
  0% {
    opacity: 0;
    filter: blur(5px);
    /* transform: scale(1.05); */
  }
  100% {
    opacity: 1;
    filter: blur(0px);
    /* transform: scale(1); */
  }
}
@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 750px) {
  /* :root {
    --maxWidth: 7.5rem;
  } */
  body {
    
  }
  #app {
    padding-top: 12vw;
  }
  .cover-video {
    display: none;
  }
  .mobile-cover-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    background: #000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    transition: all 0.5s;
    touch-action: none;
  }
  .mobile-video-tips {
    font-size: 14px;
    color: #fff;
    display: block;
    position: fixed;
    z-index: 1000;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet {
    width: 25px !important;
    height: 4px !important;
    border-radius: 0 !important;
    background: #4a5144 !important;
    opacity: 1 !important;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    background: #e50027 !important;
  }
  .content-html img{
    max-width: 100%;
    height: auto !important;
  }
  .content-html video {
    max-width: 100%;
  }
}
</style>
