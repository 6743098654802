/*
 * @Author: your name
 * @Date: 2022-01-21 11:17:24
 * @LastEditTime: 2022-10-19 17:05:26
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qjh-xiaoxxx-cn/src/api/api.js
 */
import { requestPOST } from "./request.js";

// 获取活动分类列表
export function bannerList(data) {
  return requestPOST(`/api/banner/getList`, data)
}
// 页尾链接列表
export function linkList(data) {
  return requestPOST(`/api/banner/getFootLinkList`, data)
}
// 案例标签列表
export function labelList(data) {
  return requestPOST(`/api/cases/getLabelList`, data)
}
// 案例列表
export function caseList(data) {
  return requestPOST(`/api/cases/getList`, data)
}
// 案例点击数
export function caseClick(data) {
  return requestPOST(`/api/cases/clickCase`, data)
}
// 提交意向
export function submit(data) {
  return requestPOST(`/api/about/submitIntention`, data)
}
// 新闻列表
export function newsList(data) {
  return requestPOST(`/api/news/getList`, data)
}
// 推荐新闻列表
export function newsHotList(data) {
  return requestPOST(`/api/news/getRecommendList`, data)
}
// 新闻详情
export function newsDetail(data) {
  return requestPOST(`/api/news/getInfo`, data)
}