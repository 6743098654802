import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as api from './api/api' // 引入接口列表
import { Swiper, Pagination, Autoplay } from 'swiper'; 
import 'swiper/swiper-bundle.css';
import '../src/utils'

Swiper.use([Pagination, Autoplay]);

Vue.prototype.api = api // 接口列表全局绑定
Vue.prototype.Swiper = Swiper

Vue.config.productionTip = false

// 禁用双击放大
var lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
    var now = Date.now();
    if (now - lastTouchEnd <= 300) {
        event.preventDefault();
    }
    lastTouchEnd = now;
}, {
    passive: false
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
