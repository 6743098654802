/*
 * @Author: your name
 * @Date: 2022-01-21 11:17:24
 * @LastEditTime: 2022-06-08 14:14:39
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qjh-xiaoxxx-cn/src/api/request.js
 */
// const baseUrl = (window.location.host == 'admin.quanjihuijc.com' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL_TEST)
const baseUrl = process.env.VUE_APP_API_URL

const requestGET = (path, data = {}) => {
  return new Promise((reslove, reject) => {
    let dataStr = ''
    for (let key in data) {
      dataStr += `${key}=${data[key]}&`
    }
    dataStr = dataStr.split('&')[0].length > 0 ? '?' + dataStr.substring(0, dataStr.length - 1) : dataStr.substring(0, dataStr.length - 1)
    window.fetch(baseUrl + path + dataStr, {
      method: 'GET',
      headers: {
        Authorization: window.localStorage.getItem('token') || ''
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.code == 0) {
        reslove(res)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

const requestPOST = (path, data = {}) => {
  console.log(data)
  const fd = new FormData()
  for (let item in data) {
    fd.append(item, data[item])
  }
  return new Promise((reslove, reject) => {
    window.fetch(baseUrl + path, {
      method: 'POST',
      headers: {
        Authorization: window.localStorage.getItem('token') || ''
      },
      body: fd
    }).then(res => {
      return res.json()
    }).then(res => {
      if (res.code === 0) {
        reslove(res)
      } else {
        alert('网络错误')
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export {
  requestGET,
  requestPOST
}