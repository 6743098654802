<template>
  <div class="main">
    <div class="typical-content">
      <!-- 浙江广播电视台-微光计划 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 0">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/20240416d6ffb7797462e337456489.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/202404167911270734c05cb0448631.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/20240416a01980cf4f7b9d1f280859.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">浙江广播电视台-微光计划</div>
            <ul class="typical-label">
              <li>周年庆</li>
              <li>微心愿公益</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>易捷微光计划，为乡村教师点赞、为乡村振兴助力。2022年，积极响应“健康中国”和“全民健身”号召，聚焦新时代乡村体育工作，汇聚更多“微光能量”，一起为乡村体育教育事业赋能。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、进入【乡村教师】模块，查看各个乡村教师的所在学校和个人简介，为喜欢的教师点赞。</p>
              <p>2、进入【助力生鲜】模块，在地图中查看全国各个地方的优质生鲜，点击地图中的生鲜图标，购买生鲜。</p>
              <p>3、进入【互动有礼】模块，进行抽奖。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- Go!一起走花路吧 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 1">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/20240417f0a5b3e1701f74e8297686.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/202404174049f399d2b93c0b106868.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/202404175234cf1ec2388c8b825159.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">Go!一起走花路吧</div>
            <ul class="typical-label">
              <li>太平足迹长图</li>
              <li>微信步数授权</li>
              <li>抽奖</li>
              <li>微心愿公益</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>太平人寿浙江分公司在成立20周年之际，结合健康理念及“美好”主题，发起#GO一起走花路# 活动。通过线上线下整合营销,传递健康理念，在多元互动形式中宣传公司二十周年，扩大品牌影响力。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、观看太平人寿浙江分公司的旧照片和公司介绍。</p>
              <p>2、页面左滑观看太平足迹长页面。</p>
              <p>3、首页点击【GO一起走花路吧】。</p>
              <p>4、开启微信步数授权。</p>
              <p>5、累计每日的微信步数，解锁时令勋章。</p>
              <p>6、每日步数到达3000步即可进行抽奖。</p>
              <p>7、认领微心愿，上传快递单号和购买记录即可认领完成。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 阿里技术线年终成绩单 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 2">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/2024041748db5b8ff0fcf65b551478.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/20240417f379a0162cd9bb16477556.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/20240417a27323aad7b034d2863884.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">阿里技术线年终成绩单</div>
            <ul class="typical-label">
              <li>年终总结</li>
              <li>英雄人物</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>技术线年终成绩单，是技术线同学一年一度对过去自己一年整体的回顾和总结，H5突出交互性、趣味性、和参与性，让用户在回顾过去一年的同时能够有所得、有所成长，突出荣誉感、增强归属感。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、首页点击【开启】，开启圣殿。</p>
              <p>2、在技尼斯少年出现之后，点击【进入我的圣殿】。</p>
              <p>3、进入学习、交流、分享、影响力四个征途，每完成一个征途会获得一个英雄碎片。</p>
              <p>4、集齐所有碎片获得专属英雄人物。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 华熙生物-16型肌肤测试 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 3">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/202404170383885814f2132a689598.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/20240417a5341f6847d8c1f7808904.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/202404178e14497ea6ff203d379538.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">华熙生物肌肤测试</div>
            <ul class="typical-label">
              <li>答题测试</li>
              <li>商品推荐</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>华熙生物根据美国皮肤科医生Baumann的皮肤分型方法,结合四种皮肤维度的用户答题结果测出用户的皮肤特质，并推荐适合用户使用的护肤产品。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、阅读皮肤测试的四个维度之后，进入测试。</p>
              <p>2、回答皮肤测试的12道题目。</p>
              <p>3、得出测试结果。</p>
              <p>4、根据皮肤测试的结果查看推荐的护肤产品。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 优酷十周年观影时光机 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 4">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/202404176a97457105e207d9725216.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/202404176599299067228c61708920.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/20240417557bdfaf051cfca8565807.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">优酷十周年观影时光机</div>
            <ul class="typical-label">
              <li>回忆录</li>
              <li>获取观看数据</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>优酷打造观影时光机通过汽车、宇宙、电脑、音箱、键盘、话筒、闹钟、海滩等元素插画通过动画效果引导用户回忆10年来他们的热爱剧场。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、动效完成之后向下滑动。</p>
              <p>2、观看在优酷的观影数据。</p>
              <p>3、观影完成之后，点击生成你的热爱海报。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 20年只为这件小事 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 5">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/20240422ec76e97ad0fe834d253070.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/20240422070acaab8ba30e76597501.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/202404226f9b28cabe4f5214301958.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">民生实事历年成绩单</div>
            <ul class="typical-label">
              <li>政策数据</li>
              <li>长图文</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>2023年是浙江省实行“民生实事”政策的第20年，浙江卫视向群众介绍“民生实事”政策的起源、出台的一系列政策、用于民生事业的财政制度、民生实事任务等情况，提高公众福祉，打造服务型政府的形象。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、通过加载页进入首页。</p>
              <p>2、打开背景音乐，根据页面提示向上滑动。</p>
              <p>3、页面进行年份倒计时，从2023年到2003年。</p>
              <p>4、页面向上滑动观看民生实事故事和数据。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 第二届华发好拍客 -->
      <transition name="bounce">
        <div class="typical-content-item" v-show="typicalIndex == 6">
          <div class="typical-img">
            <img class="typical-img-bg" src="https://image.xiaoxxx.cn/test/urlGather/202404226ce223b28fee2b76987905.png">
            <img class="typical-img-block1" src="https://image.xiaoxxx.cn/test/urlGather/202404228ea38c18fa6a11b9153651.png">
            <img class="typical-img-block2" src="https://image.xiaoxxx.cn/test/urlGather/202404226dd26c8f6f344d93728207.png">
          </div>
          <div class="typical-info">
            <div class="typical-title">第二届华发好拍客</div>
            <ul class="typical-label">
              <li>视频大赛</li>
            </ul>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">01</span>
                <span>案例背景</span>
              </div>
              <p>2023年是华发集团成立43周年的一年，为汇集天南海北的“华发”故事，献礼华发集团成立43周年，举办第二届华发好拍客活动。</p>
            </div>
            <div class="typical-text">
              <div class="typical-text-title">
                <span style="color: #e6082e;">02</span>
                <span>交互形式</span>
              </div>
              <p>1、通过加载页进入首页。</p>
              <p>2、查看活动规则。</p>
              <p>3、在【我的】上传参赛作品和接收点赞/评论通知。</p>
              <p>4、在【首页】浏览所有参赛作品、查看奖项设置、搜索作品。</p>
              <p>5、点击视频卡片查看视频详情，同时可点赞、评论。</p>
              <p>6、作品评选后在【获奖作品】查看每个奖项的获奖作品。</p>
            </div>
            <div class="typical-btn" @click="routerTo('Case')">
              更多案例
              <span class="typical-btn-arrows"></span>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="mobile-typical-content">
      <ul>
        <li v-for="(item, index) in listData" :key="index">
          <div @click="toCaseUrl(item.url)">
            <img class="mobile-typical-img" :src="item.img">
            <div class="mobile-typical-title">{{item.title}}</div>
            <div class="mobile-typical-label">
              <span v-for="(label, i) in item.label" :key="i">{{label}}</span>
            </div>
            <p class="mobile-typical-desc">{{item.desc}}</p>
          </div>
        </li>
      </ul>
      <div class="typical-btn" @click="routerTo('Case')">
        更多案例
        <span class="typical-btn-arrows"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['routerTo'],
  data() {
    return {
      listData: [
        {
          title: '中国太平浙江分公司20周年',
          img: 'https://image.xiaoxxx.cn/test/urlGather/2024051406f14cbcacd4ed0a806178.png',
          label: ['太平足迹长图', '微信步数授权'],
          desc: '太平人寿浙江分公司在成立20周年之际，结合健康理念及“美好”主题，发起#GO一起走花路# 活动。通过线上线下整合营销,传递健康理念，在多元互动形式中宣传公司二十周年，扩大品牌影响力。',
          url: 'https://res.xiaoxxx.com/urlGather/20230718e8d08506d85b0fea647176.MP4',
        },
        {
          title: '华熙生物-16型肌肤测试',
          img: 'https://image.xiaoxxx.cn/test/urlGather/20240514545747c5652bfadc316412.png',
          label: ['答题测试', '商品推荐'],
          desc: '华熙生物根据美国皮肤科医生Baumann的皮肤分型方法,结合四种皮肤维度的用户答题结果测出用户的皮肤特质，并推荐适合用户使用的护肤产品。',
          url: 'https://h5case5.xiaoxxx.cn/202206/huaxi-biology/dist/index.html#/',
        },
        {
          title: '浙江广播电视台-微光计划',
          img: 'https://image.xiaoxxx.cn/test/urlGather/202405145f92ae6b874d8ac9345058.png',
          label: ['周年庆', '微心愿公益'],
          desc: '易捷微光计划，为乡村教师点赞、为乡村振兴助力。2022年，积极响应“健康中国”和“全民健身”号召，聚焦新时代乡村体育工作，汇聚更多“微光能量”，一起为乡村体育教育事业赋能。',
          url: 'https://h5case5.xiaoxxx.cn/202207/shimmer/dist/index.html#/',
        },
        {
          title: '阿里技术线年终成绩单',
          img: 'https://image.xiaoxxx.cn/test/urlGather/20240514e40994dc2043176c937094.png',
          label: ['年终总结', '英雄人物'],
          desc: '技术线年终成绩单，是技术线同学一年一度对过去自己一年整体的回顾和总结，H5突出交互性、趣味性、和参与性，让用户在回顾过去一年的同时能够有所得、有所成长，突出荣誉感、增强归属感。',
          url: 'https://h5case6.xiaoxxx.cn/202301/ata-report-2022/dist/index.html#/',
        },
        {
          title: '优酷十周年观影时光机',
          img: 'https://image.xiaoxxx.cn/test/urlGather/20240514177204aeb85e6e40410840.png',
          label: ['回忆录', '获取观看数据'],
          desc: '优酷打造观影时光机通过汽车、宇宙、电脑、音箱、键盘、话筒、闹钟、海滩等元素插画通过动画效果引导用户回忆10年来他们的热爱剧场。',
          url: 'https://h5case5.xiaoxxx.cn/202208/YOUKU/index.html',
        },
        {
          title: '阿里巴巴20周年线上预热活动',
          img: 'https://image.xiaoxxx.cn/test/urlGather/202405147bd87cdbe1b2b433523205.png',
          label: ['周年庆'],
          desc: '选择借助飞行棋的形式让所有人在指 尖回忆一次阿里的“成长之路”。',
          url: 'https://h5case.xiaoxxx.com/201907/ali-game3/dist/index.html#/home',
        },
        {
          title: '民生实事历年成绩单',
          img: 'https://image.xiaoxxx.cn/test/urlGather/202405141f54b19ee4551859507768.png',
          label: ['政策数据', '长图文'],
          desc: '2023年是浙江省实行“民生实事”政策的第20年，浙江卫视向群众介绍“民生实事”政策的起源、出台的一系列政策、用于民生事业的财政制度、民生实事任务等情况，提高公众福祉，打造服务型政府的形象。',
          url: 'https://h5case6.xiaoxxx.cn/202312/workcard/dist/index.html',
        },
        {
          title: '第二届华发好拍客',
          img: 'https://image.xiaoxxx.cn/test/urlGather/2024051436c4ccfee08d7041435841.jpg',
          label: ['视频大赛'],
          desc: '2023年是华发集团成立43周年的一年，为汇集天南海北的“华发”故事，献礼华发集团成立43周年，举办第二届华发好拍客活动。',
          url: 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/',
        },
      ],
      typicalIndex: 0,
    }
  },
  mounted() {
    // 精品案例轮播
    setInterval(() => {
      if (this.typicalIndex < 6) {
        this.typicalIndex += 1
      } else {
        this.typicalIndex = 0
      }
    }, 6000);
  },
  methods: {
    toCaseUrl(url) {
      window.open(url, '_blank');
    },
  }
}
</script>

<style scoped>
.typical-content {
  width: var(--maxWidth);
  height: 630px;
  margin: 0 auto;
  position: relative;
}
.mobile-typical-content {
  display: none;
}
.typical-content-item {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
}
.bounce-enter-active {
  animation: show 1s;
}
.bounce-enter-active .typical-img {
  animation: bounce-in 1s;
}
.bounce-enter-active .typical-img-block1 {
  animation: largen 0.6s ease-in;
}
.bounce-enter-active .typical-img-block2 {
  animation: largen 1s ease-in;
}
.bounce-leave-active {
  animation: hide 0.7s;
}
.bounce-leave-active .typical-img {
  animation: bounce-out 0.7s cubic-bezier(0.45,-0.5, 1, 1);
}
.bounce-leave-active .typical-img-block1 {
  animation: largen 0.4s reverse;
}
.bounce-leave-active .typical-img-block2 {
  animation: largen 0.7s reverse;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce-in {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes largen {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounce-out {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(50px) rotate(-180deg);
  }
}
.typical-img {
  width:500px;
  position: relative;
  transform-origin: left bottom;
}
.typical-img-bg {
  width: 650px;
  position: absolute;
  bottom: 0;
  left: -150px;
}
.typical-img-block1 {
  width: 360px;
  position: absolute;
  top: 214px;
  left: -130px;
  animation: flutter 5s linear infinite;
  animation-direction: alternate;
}
.typical-img-block2 {
  width: 260px;
  position: absolute;
  bottom: 0;
  left: 220px;
  animation: flutter 8s linear infinite;
}
@keyframes flutter {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(20px, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-20px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.typical-info {
  width: 460px;
}
.typical-title {
  font-size: 36px;
  color: #252525;
}
.typical-label {
  display: flex;
  margin: 15px 0 20px;
}
.typical-label li {
  font-size: 14px;
  color: #e6082e;
  background: #feebec;
  padding: 2px 10px;
  margin-right: 10px;
  border-radius: 4px;
}
.typical-text {
  margin-bottom: 20px;
}
.typical-text-title {
  font-size: 18px;
  color: #252525;
  font-weight: 700;
  margin-bottom: 10px;
}
.typical-text>p {
  font-size: 16px;
  color: #5b5b5b;
  line-height: 26px;
}
.typical-btn {
  width: 260px;
  height: 50px;
  font-size: 20px;
  color: #e50027;
  /* color: transparent; */
  font-weight: 700;
  text-align: center;
  line-height: 50px;
  border: 2px solid #e50027;
  border-radius: 50px;
  /* margin: 50px auto 0; */
  margin-top: 30px;
  position: relative;
  background: linear-gradient(to right, #e50027 0px, #e50027 300px, rgba(0, 0, 0, 0) 300px, rgba(0, 0, 0, 0) 800px) -300px / 800px 100% no-repeat;
  /* background-clip: text; */
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
}
.typical-btn-arrows {
  width: 30px;
  height: 15px;
  display: inline-block;
  background: linear-gradient(to right, #fff 0px, #fff 30px, #e50027 30px, #e50027 80px) -40px / 80px 100% no-repeat;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/2024031289683e1f6f6f755b320110.png') center / contain no-repeat;
  transition: all 0.5s;
}
.typical-btn:hover {
  color: #fff;
  background: linear-gradient(to right, #e50027 0px, #e50027 300px, rgba(0, 0, 0, 0) 300px, rgba(0, 0, 0, 0) 800px) 0px / 800px 100% no-repeat;
}
.typical-btn:hover .typical-btn-arrows {
  background: linear-gradient(to right, #fff 0px, #fff 30px, #e50027 30px, #e50027 80px) 0px / 80px 100% no-repeat;
}

@media screen and (max-width: 750px) {
  .typical-content {
    display: none;
  }
  .mobile-typical-content {
    display: block;
  }
  .mobile-typical-content ul {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 4vw;
  }
  .mobile-typical-content li {
    width: 44vw;
    margin-bottom: 30px;
    position: relative;
  }
  .wx-open {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .mobile-typical-img {
    width: 100%;
    border-radius: 10px;
    display: block;
    margin-bottom: 12px;
  }
  .mobile-typical-title {
    font-size: 12px;
    color: #000;
  }
  .mobile-typical-label {
    margin: 5px 0 8px;
  }
  .mobile-typical-label span {
    font-size: 10px;
    color: #e50027;
    padding: 2px 8px;
    background: #fce5e9;
    margin-right: 5px;
    border-radius: 4px;
  }
  .mobile-typical-desc {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .typical-btn {
    width: 150px;
    height: 30px;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    background: #e50027;
    margin: 0 auto;
  }
  .typical-btn-arrows {
    width: 15px;
    background: #fff;
    margin-bottom: -3px;
  }
}
</style>