<template>
  <div class="main" v-fadeIn="'show'">
    <div class="data-content">
      <div class="data-model">
        <div :class="(0 + modelIndex) % 4 == 2 ? 'data-model-item data-model-active' : 'data-model-item'" :style="model[((0 + modelIndex) % 4)]">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024031123703a5e5c281212941143.png">
          <div class="data-model-item-label">渠道追踪分析</div>
        </div>
        <div :class="(1 + modelIndex) % 4 == 2 ? 'data-model-item data-model-active' : 'data-model-item'" :style="model[((1 + modelIndex) % 4)]">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403112421aed5c37257e9582940.png">
          <div class="data-model-item-label">用户行为分析</div>
        </div>
        <div :class="(2 + modelIndex) % 4 == 2 ? 'data-model-item data-model-active' : 'data-model-item'" :style="model[((2 + modelIndex) % 4)]">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403113eb93d6ce1e21230304120.png">
          <div class="data-model-item-label">访客信息实时分析</div>
        </div>
        <div :class="(3 + modelIndex) % 4 == 2 ? 'data-model-item data-model-active' : 'data-model-item'" :style="model[((3 + modelIndex) % 4)]">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240311a89e45edd69f6768877284.png">
          <div class="data-model-item-label">实时访客流量检测</div>
        </div>
      </div>
      <div class="data-model-info">
        <div class="data-model-title">{{modelText[((0 + modelIndex) % 4)].title}}</div>
        <p class="data-model-desc">{{modelText[((0 + modelIndex) % 4)].desc}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelIndex: 0,
      modelText: [
        {
          title: '渠道追踪分析',
          desc: '受访页面、来源分析、访问时长'
        },
        {
          title: '用户行为分析',
          desc: '新老用户数据统计，用户行为实时更新'
        },
        {
          title: '访客信息实时分析',
          desc: '访客系统环境、访问地域、访问时间'
        },
        {
          title: '实时访客流量检测',
          desc: 'PV，UV实时监测，表单数据分析'
        },
      ],
      model: [
        'left: 245px; top: -45px;',
        'left: 445px; top: 65px;',
        'left: 245px; top: 185px;',
        'left: 45px; top: 65px;'
      ],
    }
  },
  mounted() {
    // 数据监测2动画
    setInterval(() => {
      this.modelIndex += 1
    }, 2500)
  }
}
</script>

<style scoped>
.data-content {
  width: 100%;
  height: 500px;
  margin: 20px auto;
}
.data-model {
  width: 620px;
  height: 380px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403110c1d6df36e4d8e99939929.png') center / contain no-repeat;
  /* margin: 90px auto 0; */
  position: relative;
  top: 30px;
  left: 50%;
  transform: translateX(-50%)
}
.data-model-item {
  width: 130px;
  height: 150px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403119cf95e470e72bf81335212.png') center / contain no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 50% 50%;
  filter: opacity(65%);
  transition: all 1s;
}
.data-model-item>img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
}
.data-model .data-model-active {
  filter: opacity(100%);
  transform: scale(1.2);
}
.data-model-item-label {
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  display: inline-block;
  background: rgba(0, 0, 0, 0.35);
  padding: 6px 14px;
  border-radius: 6px;
  position: relative;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
}
.data-model-item-label::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.35);
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}
.data-model-info {
  text-align: center;
  margin-top: 40px;
}
.data-model-title {
  font-size: 22px;
  color: #f53b5b;
  margin-bottom: 5px;
}
.data-model-desc {
  font-size: 12px;
  color: #180800;
}
@media screen and (max-width: 750px) {
  .data-content {
    height: 360px;
  }
  .data-model {
    left: 50%;
    top: -30px;
    /* transform-origin: 0 0; */
    transform: translateX(-50%) scale(0.62);
    /* margin: 60px auto 0; */
  }
  .data-model-info {
    margin-top: -70px;
  }
}
</style>