<template>
  <div class="home" @click="playVideo">
    <video class="banner" ref="bannerVideo" loop autoplay data-inline-video muted preload="none" aria-label="小仙数字互动" playsinline src="//image.xiaoxxx.cn/test/urlGather/202503207a124866f5bf15db489691.mp4"></video>
    <!-- <img class="banner" src="https://image.xiaoxxx.cn/test/urlGather/20240308ba1d7a2d92c654ba919000.png" alt=""> -->
    <div class="nav">
      <div class="nav-item nav-item-active">
        <img src="https://image.xiaoxxx.cn/test/urlGather/20240522f80c3a1413050c77501882.png">
        <span>H5</span>
      </div>
      <div class="nav-item">
        <img src="https://image.xiaoxxx.cn/test/urlGather/2024041590ef649a3d9c1a7a525435.png">
        <span>小程序</span>
      </div>
      <div class="nav-item">
        <img src="https://image.xiaoxxx.cn/test/urlGather/20240415f7cf17c809d5f90c490834.png">
        <span>网站</span>
      </div>
      <div class="nav-item">
        <img src="https://image.xiaoxxx.cn/test/urlGather/2024041554a4f8a770bdd29d256404.png">
        <span>SVG动画</span>
      </div>
      <div class="nav-item">
        <img src="https://image.xiaoxxx.cn/test/urlGather/20240415891192a5d2dc6a50281546.png">
        <span>AR/VR</span>
      </div>
    </div>
    <!-- 精品案例 -->
    <div class="typical">
      <div class="home-title">
        <div>精品案例</div>
        <p>用创新性思维，高效的技术团队，洞察不断变化的市场需求，与您共同打造精品项目</p>
      </div>
      <HomeCase :routerTo="routerTo"></HomeCase>
    </div>
    <!-- 合作企业 -->
    <div class="customer" v-fadeIn="'show'">
      <div class="home-title">
        <div>合作企业</div>
        <p>携手知名企业，共同成长</p>
      </div>
      <HomeCustomer></HomeCustomer>
    </div>
    <!-- 我们的优势 -->
    <div class="advantage">
      <div class="home-title" v-fadeIn="'show'">
        <div>我们的优势</div>
        <p>十年的经验积累，丰富的行业经验，500+的优秀案例，满足您的多样化需求</p>
      </div>
      <HomeAdvantage></HomeAdvantage>
    </div>

    <!-- 服务流程 -->
    <div class="flow" v-fadeIn="'show'">
      <div class="home-title">
        <div>服务流程</div>
        <p>以专业素养，为您提供优质服务与解决方案</p>
      </div>
      <HomeFlow></HomeFlow>
    </div>

    <!-- 数据监测 -->
    <div class="data">
      <div class="home-title" v-fadeIn="'show'">
        <div>数据监测</div>
        <p>智慧监测，让您的营销更简单</p>
      </div>
      <HomeData></HomeData>
    </div>

    <!-- 行业资讯 -->
    <div class="news" v-fadeIn="'show'">
      <div class="home-title">
        <div>行业资讯</div>
      </div>
      <HomeNews :routerTo="routerTo"></HomeNews>
    </div>

    <!-- 关于我们 -->
    <div class="about">
      <img class="about-title" src="https://image.xiaoxxx.cn/test/urlGather/202403125df021244ac2ec5a231369.png">
      <div class="about-more" @click="routerTo('About')">
        查看更多
        <span class="about-more-arrows"></span>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCase from '../components/HomeCase.vue'
import HomeCustomer from '../components/HomeCustomer.vue'
import HomeAdvantage from '../components/HomeAdvantage.vue' 
import HomeFlow from '../components/HomeFlow.vue'
import HomeData from '../components/HomeData.vue'
import HomeNews from '../components/HomeNews.vue'
export default {
  name: 'Home',
  components: {
    HomeCase,
    HomeCustomer,
    HomeAdvantage,
    HomeFlow,
    HomeData,
    HomeNews
  },
  data() {
    return {
      bannerList: [],
      app: null,
      width: 0,
      height: 0,
      loadImg: [
        {
          name: 'banner',
          url: 'https://image.xiaoxxx.cn/test/urlGather/20240308ba1d7a2d92c654ba919000.png'
        },
        {
          name: 'particle',
          url: 'https://res.xiaoxxx.com/urlGather/2023103148e4273e5dd58059519188.png'
        },
        // {
        //   name: 'video',
        //   url: 'https://image.xiaoxxx.cn/test/urlGather/2024042446551b1727a10797761690.mp4'
        // }
      ],
      // 鼠标位置
      mousePos: {
        x: 0,
        y: 0
      },
      particleContainer: null,
    }
  },
  mounted() {
    this.api.bannerList().then(res => {
      this.bannerList = res.data.lists
    })
    if(window.WeixinJSBridge) {
      window.WeixinJSBridge.invoke('getNetworkType', {}, () => {
        this.$refs.bannerVideo.play();
      }, false)
    }
  },
  methods: {
    routerTo(name, data = {}) {
      this.$router.push({name: name, query: data})
    },
    playVideo() {
      this.$refs.coverVideo.play();
    }
  }
}
</script>
<style scoped>
.home {
  width: 100%;
  /* background: aquamarine; */
}
.banner {
  width: 100%;
  height: auto;
  display: block;
}
.stage {
  width: 100%;
  height: 800px;
}
.swiper {
  height: 500px;
}
.swiper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nav {
  width: var(--maxWidth);
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.nav-item {
  width: 165px;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.nav-item:hover {
  color: #fff;
  background: #f93555;
}
.nav-item img {
  width: 42px;
  height: auto;
  margin-right: 15px;
}

.home-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}
.home-title>div {
  font-size: 32px;
  color: #252525;
  position: relative;
}
.home-title>div::before {
  content: '';
  width: 40px;
  height: 15px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402200ce688622e028ae0233853.png') center / contain no-repeat;
  position: absolute;
  left: -55px;
  bottom: 0;
}
.home-title>div::after {
  content: '';
  width: 40px;
  height: 15px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022016fd1e6c35811a53747660.png') center / contain no-repeat;
  position: absolute;
  right: -55px;
  top: 5px;
}
.home-title>p {
  font-size: 18px;
  color: #666666;
  margin-top: 20px;
}

.typical {
  padding: 60px 0 0;
  overflow-y: hidden;
}

.customer {
  width: 100%;
  overflow: hidden;
  margin: 60px 0 0;
}

.advantage {
  margin: 60px auto 0;
  padding: 70px 0;
  background: #f8f7fb;
}

.flow {
  margin: 60px 0 60px;
}

.data {
  background: #fbfbfb;
  box-sizing: border-box;
  padding: 60px 0 30px;
}

.news {
  padding: 60px 0;
}

.about {
  width: 100%;
  height: 490px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/20240312e9e80256f7487e22298602.png') center / cover no-repeat;
  overflow: hidden;
}
.about-title {
  width: 760px;
  display: block;
  margin: 120px auto 0;
}
.about-more {
  width: 300px;
  height: 60px;
  font-size: 20px;
  color: #fff;
  /* color: transparent; */
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  border: 2px solid #fff;
  border-radius: 60px;
  margin: 50px auto 0;
  position: relative;
  background: linear-gradient(to right, #fff 0px, #fff 300px, rgba(0, 0, 0, 0) 300px, rgba(0, 0, 0, 0) 800px) -300px / 800px 100% no-repeat;
  /* background-clip: text; */
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s;
}
.about-more-arrows {
  width: 30px;
  height: 15px;
  display: inline-block;
  background: linear-gradient(to right, #000 0px, #000 30px, #fff 30px, #fff 80px) -40px / 80px 100% no-repeat;
  mask: url('https://image.xiaoxxx.cn/test/urlGather/2024031289683e1f6f6f755b320110.png') center / contain no-repeat;
  transition: all 0.5s;
}
.about-more:hover {
  color: #000;
  background: linear-gradient(to right, #fff 0px, #fff 300px, rgba(0, 0, 0, 0) 300px, rgba(0, 0, 0, 0) 800px) 0px / 800px 100% no-repeat;
}
.about-more:hover .about-more-arrows {
  background: linear-gradient(to right, #000 0px, #000 30px, #fff 30px, #fff 80px) 0px / 80px 100% no-repeat;
}

@media screen and (max-width: 750px) {
  .home {
    background: #f6f7fb;
    overflow: hidden;
  }
  /* .banner {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240524139068b0391c6359143794.jpg');
    width: 100%;
    height: 49.3vw;
    object-fit: cover;
  } */
  .nav {
    width: 100%;
    height: auto;
  }
  .nav-item {
    width: 20vw;
    height: 16vw;
    font-size: 12px;
    flex-direction: column;
  }
  .nav-item img {
    width: 5.6vw;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .home-title {
    margin-bottom: 30px;
  }
  .home-title>div {
    font-size: 18px;
  }
  .home-title>div::before {
    width: 6.6vw;
    left: -9.3vw;
  }
  .home-title>div::after {
    width: 6.6vw;
    right: -9.3vw;
  }
  .home-title>p {
    font-size: 12px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 15px;
    padding: 0 8vw;
  }
  .typical {
    padding: 35px 0 0;
  }
  .customer {
    background: #f7f7f7;
    margin: 35px 0 0;
    padding: 9.3vw 0;
  }
  .advantage {
    margin: 0;
    padding: 35px 0 0;
    background: #fff;
  }
  .flow {
    margin: 35px 0 0;
    padding: 35px 0;
    background: linear-gradient(215deg, #f7e2ec, #f6f7fb 50%);
  }
  .data {
    padding: 35px 0 30px;
  }
  .news {
    padding: 24px 0;
    background: #f6f7fb url('https://image.xiaoxxx.cn/test/urlGather/20240516aeac79e52cb5cff6786169.jpg') center top / contain no-repeat;
  }
  .news .home-title>div {
    color: #fff;
  }
  .news .home-title>div::before {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405164d3188326ff67300732881.png');
  }
  .news .home-title>div::after {
    background-image: url('https://image.xiaoxxx.cn/test/urlGather/202405167169c49dcd2d9e5e442922.png');
  }
  .about {
    width: 92vw;
    height: 50.6vw;
    box-sizing: border-box;
    margin: 0 auto 40px;
    border: 5px solid #fff;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.13);
  }
  .about-title {
    width: 80%;
    margin: 14vw auto 0;
  }
  .about-more {
    width: 180px;
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    box-sizing: border-box;
    border: 1px solid #fff;
    margin: 20px auto 0;
  }
  .about-more-arrows {
    width: 15px;
    height: 9px;
  }
}
</style>
