import Vue from 'vue'
Vue.directive('fadeIn', {
  inserted: function(el, binding) {
    // console.log('自定义指令', el, binding, binding.value)
    if (binding.value == 'show') {
      el.style.transition = 'all 0.5s'
      el.style.opacity = 0
      el.style.transform = 'translateY(100px) perspective(800px) rotateX(20deg)'
      // 监听元素进入视口
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        el.style.opacity = 1
        el.style.transform = 'translateY(0) perspective(800px) rotateX(0)'
        setTimeout(() => {
          el.style.transition = 'none'
          el.style.transform = ''
        }, 1000);
        observer.disconnect();
      },
      {
        rootMargin: '0px 0px 0px 0px'
      });
      // 开始监听
      observer.observe(el);
    }
    if (typeof binding.value == 'number') {
      el.style.transition = 'all 0.5s'
      el.style.opacity = 0
      el.style.transform = 'translateY(100px) perspective(800px) rotateX(30deg)'
      // 监听元素进入视口
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        setTimeout(() => {
          el.style.opacity = 1
          el.style.transform = 'translateY(0) perspective(800px) rotateX(0)'
          observer.disconnect();
          setTimeout(() => {
            el.style.transition = 'none'
            el.style.transform = ''
          }, 1000);
        }, binding.value * 1000);
      },
      {
        rootMargin: '0px 0px 0px 0px'
      });
      // 开始监听
      observer.observe(el);
    }
  }
})