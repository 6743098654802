<template>
  <div class="main">
    <div class="flow-content">
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/20240311278a07704487fd4a737146.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/20240311804627517ff0a379443653.png')"></div>
          <div class="flow-item-title">需求沟通</div>
          <p class="flow-item-desc">了解项目背景，对项目可行性进行评估</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/202403117917840121129728468338.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/2024031187fd9ab49ea7e0b7744917.png')"></div>
          <div class="flow-item-title">创意策划</div>
          <p class="flow-item-desc">分析需求，了解营销目标，输出优质的策划方案</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/20240311747cabf9a9fc2789796451.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/202403117c2e9742f136c68a341193.png')"></div>
          <div class="flow-item-title">原型输出</div>
          <p class="flow-item-desc">梳理逻辑，规避风险，输出原型图</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/2024031155af859a286d3a8e393258.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/20240407814abc3a2f568297379299.png')"></div>
          <div class="flow-item-title">定制开发</div>
          <p class="flow-item-desc">成立项目组，根据原型图进行开发，定期汇报项目进度</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/202403113c0a2ec6650dc44c148968.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/2024031183939cf95b3ec49a270353.png')"></div>
          <div class="flow-item-title">项目测试</div>
          <p class="flow-item-desc">从用户体验、功能、手机机型等多方面进行测试</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/2024031102c6335a9327b860939074.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/202403111fef339af8db8522950914.png')"></div>
          <div class="flow-item-title">交付上线</div>
          <p class="flow-item-desc">保障稳定安全的上线</p>
        </div>
      </div>
      <div class="flow-item-box">
        <div class="flow-item-header"></div>
        <div class="flow-item">
          <img class="flow-order" src="https://image.xiaoxxx.cn/test/urlGather/2024031173bdbdc0eeb378b2851446.png">
          <div class="flow-icon" style="mask-image: url('https://image.xiaoxxx.cn/test/urlGather/20240311af33cbfa45e5e788918610.png')"></div>
          <div class="flow-item-title">售后保障</div>
          <p class="flow-item-desc">实时数据监测、解决技术问题，辅助活动运营</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.flow-content {
  width: var(--maxWidth);
  margin: 85px auto 0;
  display: flex;
}
.flow-item-box {
  position: relative;
  transition: all 0.3s;
}
.flow-item-header {
  content: '';
  width: 110px;
  height: 90px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403119c71f65893cdc48f612654.png') center / contain no-repeat;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition: all 0.3s;
}
.flow-item {
  width: 121px;
  height: 265px;
  border-radius: 150px;
  box-shadow: 0px 6px 16px 0 #eeeff6;
  margin: 0 8px;
  background-image: linear-gradient(#fef8f9 0%, #ffffff 40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}
.flow-order {
  height: 35px;
  display: block;
  margin-top: 25px;
}
.flow-icon {
  width: 30px;
  height: 30px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  background: #e6082e;
  margin: 20px 0;
}
.flow-item-title {
  font-size: 20px;
}
.flow-item-desc {
  width: 110px;
  font-size: 12px;
  color: rgba(24, 8, 0, 0.6);
  text-align: center;
  margin-top: 16px;
}
.flow-item-box:hover {
  transform: scale(1.15);
}
.flow-item-box:hover .flow-item {
  background-image: linear-gradient(#fb516f, #e8062c);
  box-shadow: 0px 6px 20px 0 rgba(238, 49, 81, 0.33);
}
.flow-item-box:hover .flow-item-header {
  content: '';
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403111f5d739fc8df4ada915485.png') center / contain no-repeat;
}
.flow-item-box:hover .flow-icon {
  background: #fff;
}
.flow-item-box:hover .flow-item-title {
  color: #fff;
}
.flow-item-box:hover .flow-item-desc {
  color: #fff;
}
@media screen and (max-width: 750px) {
  .flow-content {
    width: 100vw;
    margin: 45px auto 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .flow-item-box {
    margin-bottom: 25px;
  }
  .flow-item-box:hover {
    transform: scale(1.1);
  }
  .flow-item-box:nth-of-type(1) {
    margin-left: 5vw;
  }
  .flow-item-box:nth-of-type(3) {
    margin-right: 5vw;
  }
  .flow-item-header {
    width: 15vw;
    top: -32px;
  }
  .flow-item {
    width: 22vw;
    height: 42vw;
    margin: 0 1vw;
  }
  .flow-order {
    height: 20px;
    margin-top: 10px;
  }
  .flow-icon {
    width: 20px;
    height: 20px;
    margin: 10px 0;
  }
  .flow-item-title {
    font-size: 14px;
  }
  .flow-item-desc {
    width: 90%;
    font-size: 9px;
    margin-top: 10px;
  }
}
</style>