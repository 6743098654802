<template>
  <div class="news-details">
    <div class="content">
      <div class="breadcrumb">
        新闻资讯 / {{info?.title}}
      </div>
      <div class="title">{{info?.title}}</div>
      <div class="label">
        <span v-for="(item, index) in label" :key="index">{{item}}</span>
      </div>
      <div class="date">发布时间{{info?.created_at}}</div>
      <div id="content-html" class="content-html" v-html="info?.detail">
        
      </div>
    </div>
    <div class="hot">
      <div class="hot-title">推荐阅读</div>
      <div class="mobile-hot-title">
        <div>推荐阅读</div>
      </div>
      <ul class="hot-list">
        <li class="hot-item" @click="toDetail(item.id)" v-for="item in hotList" :key="item.id">
          <div class="hot-item-title">{{item.title}}</div>
          <p class="hot-item-date">{{item.created_at.substring(0, 10)}}</p>
        </li>
      </ul>
      <ul class="mobile-hot-list">
        <li class="mobile-hot-item" @click="toDetail(item.id)" v-for="item in hotList" :key="item.id">
          <img :src="item.cover">
          <div class="mobile-hot-item-title">
            <div>{{item.title}}</div>
            <p class="mobile-hot-item-date">{{item.created_at.substring(0, 10)}}</p>
          </div>
          <div class="mobile-arrows"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: null,
      label: [],
      html: '',
      hotList: []
    }
  },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.getNewsDetail()
    }
  },
  mounted() {
    this.getNewsDetail()

    this.api.newsHotList().then(res => {
      console.log(res)
      this.hotList = res.data.lists
    })
  },
  methods: {
    getNewsDetail() {
      this.api.newsDetail({id: this.$route.query.id}).then(res => {
        if (res.code == 0) {
          this.info = res.data.info
          this.label = res.data.info.label.split(',')
        }
      })
    },
    toDetail(id) {
      if (id != this.$route.query.id) {
        this.$router.push({name: 'newsDetail', query: {id: id}})
      }
    }
  }
}
</script>

<style scoped>
.news-details {
  width: var(--maxWidth);
  margin: 0 auto 50px;
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.content {
  width: 710px;
  overflow-x: hidden;
}
.breadcrumb {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.title {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.96);
  margin: 40px 0 15px;
}
.date {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eeeeee;
}
.label {
  display: flex;
  justify-content: center;
}
.label span {
  font-size: 12px;
  color: #fff;
  padding: 3px 20px;
  background: #e70128;
  margin: 0 8px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.hot {
  width: 230px;
}
.hot-title {
  font-size: 20px;
  color: #646a73;
  box-sizing: border-box;
  padding: 0 18px;
  position: relative;
}
.hot-title::before {
  content: '';
  width: 5px;
  height: 18px;
  background: linear-gradient(#fe5f7a, #e80e34);
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mobile-hot-title {
  display: none;
}
.mobile-hot-list {
  display: none;
}
.hot-list {
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 16px 40px;
  margin-top: 12px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
}
.hot-item {
  padding: 16px 0;
  border-bottom: 1px dashed #e6e5e5;
  cursor: pointer;
}
.hot-item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  overflow: hidden;
  word-spacing: normal;
  text-overflow: ellipsis;
}
.hot-item-date {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 15px;
  background: url('https://image.xiaoxxx.cn/test/urlGather/202403158b882f4711e97ae4410936.png') left center / 14px no-repeat;
  box-sizing: border-box;
  padding-left: 24px;
}
@media screen and (max-width: 750px) {
  .news-details {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 4vw;
  }
  .breadcrumb {
    font-size: 12px;
  }
  .title {
    font-size: 18px;
    margin: 20px 0 10px;
  }
  .date {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
  }
  .label span {
    font-size: 10px;
    color: #e50027;
    padding: 3px 15px;
    background: #fce5e9;
    margin: 0 8px;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .hot {
    width: 100%;
    background: #f6f7fb url('https://image.xiaoxxx.cn/test/urlGather/20240516aeac79e52cb5cff6786169.jpg') center top / contain no-repeat;
  }
  .hot-title {
    display: none;
  }
  .mobile-hot-title {
    margin: 35px 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-hot-title>div {
    font-size: 16px;
    color: #fff;
    position: relative;
  }
  .mobile-hot-title>div::before {
    content: '';
    width: 40px;
    height: 15px;
    background: url('https://image.xiaoxxx.cn/test/urlGather/202405164d3188326ff67300732881.png') center / contain no-repeat;
    position: absolute;
    left: -55px;
    bottom: 0;
  }
  .mobile-hot-title>div::after {
    content: '';
    width: 40px;
    height: 15px;
    background: url('https://image.xiaoxxx.cn/test/urlGather/202405167169c49dcd2d9e5e442922.png') center / contain no-repeat;
    position: absolute;
    right: -55px;
    top: 5px;
  }
  .hot-list {
    display: none;
  }
  .mobile-hot-list {
    width: 92vw;
    display: block;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .mobile-hot-item {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .mobile-hot-item>img {
    width: 26.6vw;
    height: 17.3vw;
    object-fit: cover;
    border-radius: 8px;
    flex-shrink: 0;
  }
  .mobile-hot-item-title {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.96);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 12px;
  }
  .mobile-hot-item-date {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }
  .mobile-arrows {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 9vw;
    flex-shrink: 0;
    background: #e60b31 url('https://image.xiaoxxx.cn/test/urlGather/2024052036ee592737a1306b969603.png') center / 20% no-repeat;
  }
}
</style>