<template>
  <div class="mian" ref="customer">
    <div class="customer-content">
      <div :class="customerShow ? 'customer-row customer-show' : 'customer-row'">
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308340d2893291f0d23402817.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030811e6bf1b192087d3160487.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308870f31f76932f079833783.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308da4475e0caa9db62126000.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030807fad4336b7f853b154184.png">
        </div>
      </div>
      <div :class="customerShow ? 'customer-row customer-show2' : 'customer-row'">
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308993301f44f83086b455455.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308de0ea5115a5b0830715382.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308febae968c80333c3516101.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308a3787bea55a819f2120362.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403089eed895e7d221633832194.png">
        </div>
      </div>
      <div :class="customerShow ? 'customer-row customer-show3' : 'customer-row'">
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403083aee169325533051123212.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030871cb942a7b927912676052.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403089029e17075d50d52992270.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030830e169db7779d73c177486.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308c2e78a3f40fc0c4a874739.png">
        </div>
      </div>
      <div :class="customerShow ? 'customer-row customer-show4' : 'customer-row'">
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308ad2dcb5b1a077a9b589090.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030888105d6910556043229289.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403082fea177855fd79d7399097.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308ee1aa8e1f107ec3f812139.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308b16b32dc912cf53d635399.png">
        </div>
      </div>
      <div :class="customerShow ? 'customer-row customer-show5' : 'customer-row'">
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308f7e83008eec21487196920.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403081c7a55d032672751878829.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240308c4f5d7326d2971c9737422.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403088f83d97ea46d735c337413.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/2024030814b13157327a6b20873677.png">
        </div>
        <div class="customer-item">
          <img src="https://image.xiaoxxx.cn/test/urlGather/202403087be2c9bdb9f7ecec348483.png">
        </div>
      </div>
    </div>
    <div class="mobile-customer-content" v-show="customerShow">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062896d3026f0d42e4e2404360.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628660438808d63f11d327462.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406281d184c12c6aa3e7e307216.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628d3ac0fb00530f23e666277.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628e08bdd4177964c58115853.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062833adcadad66808d0819069.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406282d93088321176247723553.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062821aec97e2fec5893179063.png">
      
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628d8505a2666b0e727306652.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062832ff8fd49f481461634557.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406284914abf6bc935bb6840548.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406283fceaac0b927c727833190.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406287def778a7e43f3a0966034.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628c0f1e14db2a89e03669174.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628f2792300e927c924604923.png">
      <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406283904081ac10a59c2639559.png">
      <div class="mobile-customer-content-small">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628d61c1141316b4e54554791.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/202406288031888cfc6d5608740109.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628f6090b1125e90f59377532.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062888a078ccd4d986c7688331.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628698dd4d2a05ca73a431769.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062824a559e09534c945550208.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628e9e56e2ad7ef2160106041.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/2024062818ce6f24380571c5294447.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628eb28e90b284e6ffa113801.png">
        <img class="mobile-customer-item" src="https://image.xiaoxxx.cn/test/urlGather/20240628f816603ce6f21b52222343.png">
        <div class="mobile-customer-xx">
          <img src="https://image.xiaoxxx.cn/test/urlGather/20240628254e78ff9d0a7ed1370379.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerShow: false,
    }
  },
  mounted() {
    // 监听服务进入视口
    const customer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        if (!this.complete) {
          this.customerShow = true
        }
      },
      {
        rootMargin: '10px 0px 50px 0px'
      }
    );
    // 开始监听
    customer.observe(this.$refs.customer);
    
  }
}
</script>

<style scoped>
.customer-content {
  width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.mobile-customer-content {
  display: none;
}
.customer-row {
  display: flex;
  opacity: 0;
}
.customer-show {
  animation: rightShow 1s forwards;
}
.customer-show2 {
  animation: leftShow 1s forwards;
}
.customer-show3 {
  animation: rightShow 1s 0.4s forwards;
}
.customer-show4 {
  animation: leftShow 1s 0.4s forwards;
}
.customer-show5 {
  animation: rightShow 1s 0.8s forwards;
}
@keyframes leftShow {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rightShow {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.customer-item {
  width: 140px;
  height: 60px;
  background: #fff;
  box-shadow: 6px 0 20px 0 #eeeff6;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.customer-item:hover {
  box-shadow: 0 0 0 0 #eeeff6;
}
.customer-item img {
  width: 100px;
  height: 25px;
  object-fit: contain;
}
.customer-row:nth-of-type(1) {
  align-self: center;
}
.customer-row:nth-of-type(2) {
  align-self: flex-start;
}
.customer-row:nth-of-type(3) {
  align-self: flex-end;
}
.customer-row:nth-of-type(4) {
  align-self: center;
}
.customer-row:nth-of-type(5) {
  align-self: center;
}
@media screen and (max-width: 750px) {
  .customer-content {
    display: none;
  }
  .mobile-customer-content {
    width: 84vw;
    height: 84vw;
    display: block;
    background: url('https://image.xiaoxxx.cn/test/urlGather/202406283fa6a219b154cd70662341.png') center / 100%  100% no-repeat;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
  .mobile-customer-item {
    width: 14vw;
    height: 14vw;
    position: absolute;
    opacity: 0;
  }
  .mobile-customer-item:nth-of-type(1) {
    left: 42.3vw;
    top: 4vw;
    animation: show 0.3s forwards;
  }
  .mobile-customer-item:nth-of-type(2) {
    left: 54vw;
    top: 9vw;
    animation: show 0.3s 0.1s forwards;
  }
  .mobile-customer-item:nth-of-type(3) {
    left: 63.3vw;
    top: 18vw;
    animation: show 0.3s 0.2s forwards;
  }
  .mobile-customer-item:nth-of-type(4) {
    left: 68.8vw;
    top: 30vw;
    animation: show 0.3s 0.3s forwards;
  }
  .mobile-customer-item:nth-of-type(5) {
    left: 68.8vw;
    top: 43.5vw;
    animation: show 0.3s 0.4s forwards;
  }
  .mobile-customer-item:nth-of-type(6) {
    left: 63.3vw;
    top: 55.5vw;
    animation: show 0.3s 0.5s forwards;
  }
  .mobile-customer-item:nth-of-type(7) {
    left: 54vw;
    top: 65vw;
    animation: show 0.3s 0.6s forwards;
  }
  .mobile-customer-item:nth-of-type(8) {
    left: 42.3vw;
    top: 70.3vw;
    animation: show 0.3s 0.7s forwards;
  }
  .mobile-customer-item:nth-of-type(9) {
    left: 28vw;
    top: 70.3vw;
    animation: show 0.3s 0.8s forwards;
  }
  .mobile-customer-item:nth-of-type(10) {
    left: 15.5vw;
    top: 65vw;
    animation: show 0.3s 0.9s forwards;
  }
  .mobile-customer-item:nth-of-type(11) {
    left: 6.5vw;
    top: 55.5vw;
    animation: show 0.3s 1s forwards;
  }
  .mobile-customer-item:nth-of-type(12) {
    left: 1.5vw;
    top: 30vw;
    animation: show 0.3s 1.1s forwards;
  }
  .mobile-customer-item:nth-of-type(13) {
    left: 1.5vw;
    top: 43.5vw;
    animation: show 0.3s 1.2s forwards;
  }
  .mobile-customer-item:nth-of-type(14) {
    left: 6.5vw;
    top: 18vw;
    animation: show 0.3s 1.3s forwards;
  }
  .mobile-customer-item:nth-of-type(15) {
    left: 15.5vw;
    top: 9vw;
    animation: show 0.3s 1.4s forwards;
  }
  .mobile-customer-item:nth-of-type(16) {
    left: 28vw;
    top: 4vw;
    animation: show 0.3s 1.5s forwards;
  }
  @keyframes show {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .mobile-customer-content-small {
    width: 56vw;
    height: 56vw;
    /* background: url('https://image.xiaoxxx.cn/test/urlGather/202405148b4f973ed7f5693b722196.png') center / 85% no-repeat; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(1) {
    left: 27vw;
    top: 4.5vw;
    animation: show 0.3s 2.4s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(2) {
    left: 36.5vw;
    top: 11.5vw;
    animation: show 0.3s 2.3s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(3) {
    left: 40vw;
    top: 22.5vw;
    animation: show 0.3s 2.2s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(4) {
    left: 36.5vw;
    top: 33.5vw;
    animation: show 0.3s 2.1s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(5) {
    left: 27vw;
    top: 41vw;
    animation: show 0.3s 2s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(6) {
    left: 15vw;
    top: 41vw;
    animation: show 0.3s 1.9s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(7) {
    left: 5.5vw;
    top: 33.5vw;
    animation: show 0.3s 1.8s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(8) {
    left: 2vw;
    top: 22.5vw;
    animation: show 0.3s 1.7s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(9) {
    left: 5.5vw;
    top: 11.5vw;
    animation: show 0.3s 1.6s forwards;
  }
  .mobile-customer-content-small .mobile-customer-item:nth-of-type(10) {
    left: 15vw;
    top: 4.5vw;
    animation: show 0.3s 1.5s forwards;
  }
  .mobile-customer-xx {
    width: 20vw;
    height: 20vw;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    opacity: 0;
    animation: show 0.5s 2.6s forwards;
  }
  .mobile-customer-xx>img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -38%);
  }
}
</style>