<template>
  <div class="news">
    <Banner></Banner>
    <router-view></router-view>
  </div>
</template>

<script>
import Banner from '../../components/Banner.vue'
export default {
  components: {
    Banner
  },
}
</script>

<style scoped>
.news {
  background: #fbfbfb;
  overflow: hidden;
}
.banner {
  width: 100%;
  height: 500px;
  position: relative;
  background: #000;
}
.banner-flow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.banner-flow-rotate {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  transform: rotate(-30deg);
}
.banner-flow-column {
  margin: 0 10px 0 0;
  flex-shrink: 0;
}
.banner-flow-column:nth-of-type(1) {
  animation: move 20s linear infinite;
}
.banner-flow-column:nth-of-type(2) {
  animation: move 32s linear infinite;
}
.banner-flow-column:nth-of-type(3) {
  animation: move 22s linear infinite;
}
.banner-flow-column:nth-of-type(4) {
  animation: move 28s linear infinite;
}
.banner-flow-column:nth-of-type(5) {
  animation: move 16s linear infinite;
}
.banner-flow-item {
  width: 360px;
  height: 710px;
  box-sizing: border-box;
  padding: 20px;
  background: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/20240221e410bac21c06af11521858.png') center / 100% 100% no-repeat;
  /* transform: rotate(-30deg); */
  margin-top: 20px;
}
.banner-flow-item-img {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  mask: url('https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/2024022167dd95c144d72868211953.png') center / 100% 100% no-repeat;
}
@keyframes move {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(-70%);
  }
}
.banner-flow-mask {
  width: 100%;
  height: calc(100% + 2px);
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
}
.banner-text {
  width: 960px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: show 2s 0.3s forwards;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 750px) {
  .banner {
    height: 50vw;
  }
  .banner-flow-item {
    width: 120px;
    height: 236px;
    padding: 10px;
    margin-top: 10px;
  }
  .banner-text {
    content: url('https://image.xiaoxxx.cn/test/urlGather/20240520a1ccf5a72f5b642f303679.png');
    width: 85%;
  }
}
</style>