<template>
  <div class="footer">
    <div class="footer-content">
      <img class="footer-logo" src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/test/urlGather/202402216e4bb610397449c8916397.png">
      <!-- <div class="footer-line"></div> -->
      <div class="footer-bottom">
        <div class="footer-qrcode">
          <div class="footer-qrcode-item">
            <img src="https://image.xiaoxxx.cn/test/urlGather/2024031820dcfb75481fe60f977276.png">
            <p>扫码关注</p>
          </div>
          <div class="footer-qrcode-item">
            <img src="https://image.xiaoxxx.cn/test/urlGather/202403186e0c29d564648695575079.png">
            <p>扫码咨询</p>
          </div>
        </div>
        <div class="footer-contact">
          <div class="footer-contact-list">
            <div class="footer-contact-title">友情链接</div>
            
            <a
              class="footer-contact-item"
              v-for="(item, index) in linkList"
              :key="index"
              :href="item.link_url"
            >{{item.link_name}}</a>
          </div>
          <div  class="footer-contact-list">
            <div class="footer-contact-title">联系电话</div>
            <span class="footer-contact-item">姓名：墨风</span>
            <span class="footer-contact-item">合作微信/钉钉/TEL：13777378543</span>
            <span class="footer-contact-item">邮箱：yeqing@8think.com</span>
          </div>
        </div>
      </div>
      <div class="footer-line"></div>
      <div class="footer-copyright">
        <span>Copyright 2015-2024</span>
        <span>版权所有：杭州小仙科技有限公司</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备16000551号-6</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      linkList: []
    }
  },
  created() {
    this.api.linkList().then(res => {
      this.linkList = res.data.lists
    })
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  min-width: var(--maxWidth);
  background: #181818;
  padding: 50px 0 30px;
}
.footer-content {
  width: var(--maxWidth);
  margin: 0 auto;
}
.footer-logo {
  width: 270px;
  margin-bottom: 30px;
}
.footer-line {
  width: 100%;
  height: 1px;
  background: #555555;
  margin: 30px 0;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
}
.footer-qrcode {
  display: flex;
}
.footer-qrcode-item {
  margin-right: 35px;
}
.footer-qrcode-item>img {
  width: 120px;
  height: 120px;
  display: block;
}
.footer-qrcode-item>p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
  margin-top: 15px;
}
.footer-contact {
  display: flex;
  justify-content: flex-end;
}
.footer-contact-list {
  margin-left: 80px;
  display: flex;
  flex-direction: column;
}
.footer-contact-title {
  font-size: 16px;
  color: #fff;
  margin-bottom: 35px;
}
.footer-contact-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
}
.footer-copyright {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
}
.footer-copyright>span:nth-of-type(2) {
  margin: 0 20px;
}
.footer-copyright>a {
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}
.footer-copyright>a:visited {
  color: rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 750px) {
  .footer {
    min-width: 100%;
    padding: 20px 0 30px;
    position: relative;
  }
  .footer::before {
    content: '';
    width: 100%;
    height: 4px;
    background: url('https://image.xiaoxxx.cn/test/urlGather/20240516673974b6b6d294de615069.png') center / 100% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }
  .footer-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 4vw;
  }
  .footer-logo {
    width: 150px;
  }
  .footer-line {
    display: none;
  }
  .footer-bottom {
    margin-top: 35px;
    flex-direction: column;
  }
  .footer-qrcode {
    padding-bottom: 20px;
    border-bottom: 1px solid #555555;
    margin-bottom: 20px;
  }
  .footer-qrcode-item {
    margin-right: 40px;
  }
  .footer-qrcode-item>img {
    width: 90px;
    height: 90px;
  }
  .footer-qrcode-item>p {
    font-size: 10px;
    margin-top: 10px;
  }
  .footer-contact {
    justify-content: flex-start;
  }
  .footer-contact-list {
    margin-left: 0;
    margin-right: 60px;
  }
  .footer-contact-title {
    font-size: 10px;
    margin-bottom: 16px;
  }
  .footer-contact-item {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
</style>
